import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Collapse,
    Input,
    Card,
    Form,
    DatePicker,
    Button,
    Switch,
    message,
    Upload,
    Image,
    Tooltip,
    Modal,
} from "antd";
import {
    PlusOutlined,
    MinusOutlined,
    LoadingOutlined,
    SearchOutlined,
    CopyOutlined,
    FormOutlined,
    EyeOutlined,
    CalendarOutlined
} from "@ant-design/icons";
import * as getSuggestionService from "../../services/getSuggestion";
import { useDispatch } from "react-redux";
import Upload1 from "./uploadMultistep";
import axios from "axios";
import * as FooterLogoService from "../../services/FooterLogo";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { MEDIA_UPLOAD_URL } from "../../Helper";
import 'suneditor/dist/css/suneditor.min.css';
import SunEditor from 'suneditor-react';
import SkeletonIndustryStandard from "./SkeletonIndustryStandard";

import TripleToggle from "../../components/trippleToggle/trippleToggle";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link

} from "suneditor/src/plugins";
const { Panel } = Collapse;


function CommonFourthStep({ reportType, props }) {
    var dispatch = useDispatch();
    const [editorState1, setEditorState1] = useState("");
    const [editorState2, setEditorState2] = useState("");
    const [editorState3, setEditorState3] = useState("");
    const [editorState4, setEditorState4] = useState("");
    const [editorState5, setEditorState5] = useState("");
    const [editorState6, setEditorState6] = useState("");
    const [editorState7, setEditorState7] = useState("");
    const [editorState8, setEditorState8] = useState("");
    const [editorState9, setEditorState9] = useState("");
    const [editorState10, setEditorState10] = useState("");
    const [editorState11, setEditorState11] = useState("");
    const [editorState12, setEditorState12] = useState("");
    const [editorState13, setEditorState13] = useState("");
    const [sidebarState, setsidebarState] = useState();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [footerLogo, setFooterLogo] = useState([]);
    const [selectedlogs, setselectedLogos] = useState([]);
    const [sign, setSign] = useState([]);
    const [form] = Form.useForm();
    const [modalIndustry, setModalIndustry] = useState(false);
    const [industryContent, setindustryContent] = useState("");
    const [search, setSearch] = useState("");
    const [filterStandards, setFilterStandards] = useState([]);

    const handleClick = (item1) => {
        setModalIndustry(true);
        sidebarState.filter((item) =>
            item.id === item1 ? setindustryContent(item) : ""
        );
    };

    const takefileList = (list) => {
        props.values.attachments = list;
    };
    const onEditorStateChange1 = (editorData) => {
        setEditorState1(editorData);
        props.values.claim_history_comments = editorData;
        props.handleEditor(editorData,
            "claim_history_comments"
        );
    };

    const onEditorStateChange2 = (editorData) => {
        setEditorState2(editorData);
        props.values.observation_comments = editorData;
        props.handleEditor(
            editorData,
            "observation_comments"
        );
    };

    const onEditorStateChange3 = (editorData) => {
        setEditorState3(editorData);
        props.values.applicable_field_text = editorData;
        props.handleEditor(
            editorData,
            "applicable_field_text"
        );
    };

    const onEditorStateChange4 = (editorData) => {
        setEditorState4(editorData);
        props.values.industry_standards_text = editorData;
        props.handleEditor(
            editorData,
            "industry_standards_text"
        );
    };

    const onEditorStateChange5 = (editorData) => {
        setEditorState5(editorData);
        props.values.conclusion_text = editorData;
        props.handleEditor(
            editorData,
            "conclusion_text"
        );
    };
    const noAction = (editorData) => {
        console.warn(editorData);
    };

    const onEditorStateChange6 = (editorData) => {
        setEditorState6(editorData);
        props.values.cause_text = editorData;
        props.handleEditor(
            editorData,
            "cause_text"
        );
    };

    const onEditorStateChange7 = (editorData) => {
        setEditorState7(editorData);
        props.values.conf_comment = editorData;
        props.handleEditor(
            editorData,
            "conf_comment"
        );
    };

    const onEditorStateChange8 = (editorData) => {
        setEditorState8(editorData);
        props.values.footer_text = editorData;
        props.handleEditor(
            editorData,
            "footer_text"
        );
    };

    const onEditorStateChange9 = (editorData) => {
        setEditorState9(editorData);
        props.values.private_notes = editorData;
        props.handleEditor(
            editorData,
            "private_notes"
        );
    };
    const onEditorStateChange10 = (editorData) => {
        setEditorState10(editorData);
        props.values.manufacturing_issue = editorData;
        props.handleEditor(
            editorData,
            "manufacturing_issue"
        );
    };
    const onEditorStateChange11 = (editorData) => {
        setEditorState11(editorData);
        props.values.site_rel_issue = editorData;
        props.handleEditor(
            editorData,
            "site_rel_issue"
        );
    };
    const onEditorStateChange12 = (editorData) => {
        setEditorState12(editorData);
        props.values.perfo_rel_issue = editorData;
        props.handleEditor(
            editorData,
            "perfo_rel_issue"
        );
    };
    const onEditorStateChange13 = (editorData) => {
        setEditorState13(editorData);
        props.values.insta_rel_issue = editorData;
        props.handleEditor(
            editorData,
            "insta_rel_issue"
        );
    };

    const SunEditorOpts = {
        showPathLabel: false,
        imageMultipleFile: true,
        imageWidth: '150px',
        imageHeight: '150px',
        height: "auto",
        minHeight: "200px",
        requestHeaders: {
            "X-Sample": "sample"
        },
        plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link
        ],
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle"],
            [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript"
            ],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            ["align", "horizontalRule", "lineHeight"],
            ["outdent", "indent"], ["list"],
            ["table", "link", "image"],
            ["fullScreen", "showBlocks", "preview",],
        ],
        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS",
            "Roboto"
        ]
    };

    const onImageUploadBefore = (files, info, uploadHandler) => {
        const formData = new FormData()
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
            },
        };
        formData.append('file', files[0])
        axios.post(MEDIA_UPLOAD_URL, formData, config)
            .then((res) => {
                const response = {
                    // The response must have a "result" array.
                    "result": [
                        {
                            "url": res.data.source_url,
                            "name": files[0].name,
                            "size": files[0].size
                        },
                    ]
                }
                uploadHandler(response)
            })
    }
    const getSuggestion = (label, category) => {
        setLoading(true);
        let suggestiondata = {
            label: label,
            category: category,
        };
        dispatch(getSuggestionService.getindStandard(suggestiondata))
            .then((res) => {
                setsidebarState(res);
                setLoading(false);
            })
            .catch((err) => {
                // Extract and display a meaningful error message
                let errorMessage;

                if (err.response) {
                    // The request was made and the server responded with a status code outside of the 2xx range
                    errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
                } else if (err.request) {
                    // The request was made but no response was received
                    errorMessage = "No response received from the server. Please check your network connection.";
                } else {
                    // Something else happened while setting up the request
                    errorMessage = `An unexpected error occurred: ${err.message}`;
                }

                // Display the error message in an alert or use a UI notification component
                alert(errorMessage);
            });
    };





    const fetchData = (params = {}) => {
        setLoading(true);
        dispatch(FooterLogoService.getLogos())
            .then((res) => {
                setFooterLogo(res);
                setLoading(false);
            })
            .catch((err) => {
                console.info("Get Error Edit State...");
                setLoading(false);
            });
    };

    useEffect(() => {
        props.values.claim_history_comments
            ? setEditorState1(props.values.claim_history_comments)
            : setEditorState1("");
        props.values.observation_comments
            ? setEditorState2(props.values.observation_comments)
            : setEditorState2("");
        props.values.applicable_field_text
            ? setEditorState3(props.values.applicable_field_text)
            : setEditorState3("");
        props.values.industry_standards_text
            ? setEditorState4(props.values.industry_standards_text)
            : setEditorState4("");
        props.values.conclusion_text
            ? setEditorState5(props.values.conclusion_text)
            : setEditorState5("");
        props.values.cause_text
            ? setEditorState6(props.values.cause_text)
            : setEditorState6("");
        props.values.conf_comment
            ? setEditorState7(props.values.conf_comment)
            : setEditorState7("");
        props.values.footer_text
            ? setEditorState8(props.values.footer_text)
            : setEditorState8("");
        props.values.private_notes
            ? setEditorState9(props.values.private_notes)
            : setEditorState9("");
        props.values.manufacturing_issue
            ? setEditorState10(props.values.manufacturing_issue)
            : setEditorState10("");
        props.values.site_rel_issue
            ? setEditorState11(props.values.site_rel_issue)
            : setEditorState11("");
        props.values.perfo_rel_issue
            ? setEditorState12(props.values.perfo_rel_issue)
            : setEditorState12("");
        props.values.insta_rel_issue
            ? setEditorState13(props.values.insta_rel_issue)
            : setEditorState13("");
        setselectedLogos(props.values?.footerLogo);
        setSign(props.values?.signId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values.footerLogo]);

    const normFile = (e) => {
        console.log("Upload event:", e);

        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const sendTextToEditor = (text, label) => {
        if (label === "claim-history") {
            setEditorState1(editorState1 + text);
            onEditorStateChange1(editorState1 + text);


        } else if (label === "observations_and_comments") {
            setEditorState2(editorState2 + text);
            onEditorStateChange2(editorState2 + text);

        } else if (label === "applicable-field-tests") {
            setEditorState3(editorState3 + text);
            onEditorStateChange3(editorState3 + text);

        } else if (label === "industry_standards") {
            setEditorState4(editorState4 + text);
            onEditorStateChange4(editorState4 + text);

        } else if (label === "conclusion") {
            setEditorState5(editorState5 + text);
            onEditorStateChange5(editorState5 + text);

        } else if (label === "cause") {
            setEditorState6(editorState6 + text);
            onEditorStateChange6(editorState6 + text);

        } else if (label === "confidential-information") {
            setEditorState7(editorState7 + text);
            onEditorStateChange7(editorState7 + text);

        } else if (label === "footer-text") {
            setEditorState8(editorState8 + text);
            onEditorStateChange8(editorState8 + text);

        } else if (label === "private-notes") {
            setEditorState9(editorState9 + text);
            onEditorStateChange9(editorState9 + text);
        }
        else if (label === "manufacturing_issue") {
            setEditorState10(editorState10 + text);
            onEditorStateChange10(editorState10 + text);
        }
        else if (label === "site_rel_issue") {
            setEditorState11(editorState11 + text);
            onEditorStateChange11(editorState11 + text);
        }
        else if (label === "perfo_rel_issue") {
            setEditorState12(editorState12 + text);
            onEditorStateChange12(editorState12 + text);
        }
        else if (label === "insta_rel_issue") {
            setEditorState13(editorState13 + text);
            onEditorStateChange13(editorState13 + text);
        }
    };




    const handleChange = (e) => {
        //setData(e.target.value);
        console.warn("input", e);
        console.log(`selected ${e.target.value}`);
        console.log(sign);
    };

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }

        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }

        return isJpgOrPng && isLt2M;
    };

    const handleChangeImage = (info) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }

        if (info.file.status === "done") {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
                props.values.signurl = url;
            });
        }
    };

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const uploadImage = async (options) => {
        const { onSuccess, onError, file } = options;

        const fmData = new FormData();
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
            },
        };
        fmData.append("file", file);
        try {
            const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
            onSuccess("Ok");
            setSign(res);
            console.log("server res: ", res);
            props.values.signature = res?.data?.id;
            console.log('props.values.signature', props.values.signature);
        } catch (err) {
            console.log("Eroor: ", err);
            const error = new Error("Some error");
            onError({ err });
            console.warn(error);
        }
    };

    useEffect(() => {
        setselectedLogos(props.values?.footerLogo)
        setImageUrl(props.values?.signurl)
        fetchData();
        props.values.claim_history_comments ? setEditorState1(props.values.claim_history_comments) : setEditorState1("");
        props.values.observation_comments ? setEditorState2(props.values.observation_comments) : setEditorState2("");
        props.values.applicable_field_text ? setEditorState3(props.values.applicable_field_text) : setEditorState3("");
        props.values.industry_standards_text ? setEditorState4(props.values.industry_standards_text) : setEditorState4("");
        props.values.conclusion_text ? setEditorState5(props.values.conclusion_text) : setEditorState5("");
        props.values.cause_text ? setEditorState6(props.values.cause_text) : setEditorState6("");
        props.values.conf_comment ? setEditorState7(props.values.conf_comment) : setEditorState7("");
        props.values.footer_text ? setEditorState8(props.values.footer_text) : setEditorState8("");
        props.values.private_notes ? setEditorState9(props.values.private_notes) : setEditorState9("");
        props.values.manufacturing_issue ? setEditorState10(props.values.manufacturing_issue) : setEditorState10("");
        props.values.site_rel_issue ? setEditorState11(props.values.site_rel_issue) : setEditorState11("");
        props.values.perfo_rel_issue ? setEditorState12(props.values.perfo_rel_issue) : setEditorState12("");
        props.values.insta_rel_issue ? setEditorState13(props.values.insta_rel_issue) : setEditorState13("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.info('Useffect Called')
        if (sidebarState) {
            const filteredData = sidebarState.filter((entry) =>
                entry?.title?.toLowerCase().includes(search.toLowerCase())
            );
            setFilterStandards(filteredData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const DateFormat = "MM-DD-YYYY";

    return (
        <>
            <Form
                name="report"
                form={form}
                autoComplete="off"
                layout="vertical"
                onChange={handleChange}
            >
                <Card className="reportCard">
                    <div className="reportCardBody">
                        <div className="formSection">
                            <div className="CollapseSection">
                                <div className="customObservationPanel">
                                    <Row gutter={16}>
                                        <Col xs={24} md={16} xl={16}>
                                            <Collapse
                                                defaultActiveKey={
                                                    props.values.stepFourTogg
                                                        ? props.values.stepFourTogg
                                                        : ["6", "7", "8"]
                                                }
                                                onChange={props.handleChange("stepFourTogg")}
                                                className="reportCollapse"
                                                expandIcon={({ isActive }) =>
                                                    isActive ? (
                                                        <MinusOutlined className="custom-icon" />
                                                    ) : (
                                                        <PlusOutlined className="custom-icon" />
                                                    )
                                                }
                                                ghost
                                            >
                                                <Panel header="Observations Information" key={6}>
                                                    <Row gutter={16}>
                                                        <Col xs={24} md={24} xl={24}>
                                                            <div className="formGroup">

                                                                <Form.Item
                                                                    label="Sending Sample?"
                                                                    name="sending_samples"
                                                                >
                                                                    {console.warn("tripleToggle")}
                                                                    <TripleToggle
                                                                        value={props.values.sending_samples}
                                                                        onToggle={props.handleChange('sending_samples')}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            {props.isOpen.sendingSampleField === true && props.values.sending_samples === 'Yes' ? (
                                                                <div id="myDiv2" className="hidden-section">
                                                                    <Row gutter={16}>
                                                                        <Col xs={24} md={8} xl={8}>
                                                                            <div className="formGroup">
                                                                                <Form.Item
                                                                                    label="Shipping Company"
                                                                                    name="shipping_company"
                                                                                >
                                                                                    <Input
                                                                                        placeholder="Shipping Company"
                                                                                        className="formControl"
                                                                                        type="text"
                                                                                        onChange={props.handleChange(
                                                                                            "shipping_company"
                                                                                        )}
                                                                                        defaultValue={
                                                                                            props.values.shipping_company
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24} md={8} xl={8}>
                                                                            <div className="formGroup">
                                                                                <Form.Item
                                                                                    label="Tracking Number"
                                                                                    name="tracking_number"
                                                                                >
                                                                                    <Input
                                                                                        placeholder="Tracking Number"
                                                                                        className="formControl"
                                                                                        onChange={props.handleChange(
                                                                                            "tracking_number"
                                                                                        )}
                                                                                        defaultValue={
                                                                                            props.values.tracking_number
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={24} md={8} xl={8}>
                                                                            <div className="formGroup">
                                                                                <Form.Item
                                                                                    label="Date Shipped"
                                                                                    name="date_shipped"
                                                                                >
                                                                                    <Input.Group compact className="mt-0">
                                                                                        <Input
                                                                                            placeholder="Date Shipped"
                                                                                            className="formControl"
                                                                                            onChange={(e) =>
                                                                                                props.handleDateformate(
                                                                                                    e,
                                                                                                    'date_shipped',
                                                                                                    'text'
                                                                                                )
                                                                                            }
                                                                                            value={props.values.date_shipped}
                                                                                            onFocus={() => props.setCalOpen6(!props.calOpen6)}
                                                                                            onKeyUp={() => props.setCalOpen6(false)}
                                                                                            onBlur={() => props.setCalOpen6(false)}
                                                                                        />

                                                                                        <Button
                                                                                            className="btnAddOn"
                                                                                            icon={<CalendarOutlined />}
                                                                                            onClick={() => props.setCalOpen6(!props.calOpen6)}
                                                                                        />
                                                                                    </Input.Group>
                                                                                    <DatePicker
                                                                                        className="formControl dateHide"
                                                                                        format={DateFormat}
                                                                                        open={props.calOpen6}
                                                                                        style={{ visibility: 'hidden', width: 0 }}
                                                                                        onChange={(e) =>
                                                                                            props.handleDateformate(
                                                                                                e,
                                                                                                'date_shipped',
                                                                                                'date'
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Form.Item>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <Row className="claim-history-section">
                                                                <div className="formGroup">
                                                                    <Form.Item
                                                                        label="Claim History and Comments of Involved Parties"
                                                                        name="claim_his_togg"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked={
                                                                                props.isOpen.claimHistoryField ===
                                                                                    true &&
                                                                                    props.values.claim_his_togg === true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={props.handleChange(
                                                                                "claim_his_togg"
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.claimHistoryField === true &&
                                                                    props.values.claim_his_togg === true ? (
                                                                    <div className="text-editor-wrapper">

                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                getSuggestion(
                                                                                    "claim-history",
                                                                                    reportType
                                                                                );
                                                                            }}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState1}
                                                                            onChange={onEditorStateChange1}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                            <Row className="physcial-description-section">
                                                                <div className="formGroup">
                                                                    <Form.Item
                                                                        label="Observations or Physical Description and Comments"
                                                                        name="observe_togg"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked={
                                                                                props.isOpen.observationField ===
                                                                                    true &&
                                                                                    props.values.observe_togg === true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={props.handleChange(
                                                                                "observe_togg"
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.observationField === true &&
                                                                    props.values.observe_togg === true ? (
                                                                    <div className="text-editor-wrapper">

                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                getSuggestion(
                                                                                    "observations_and_comments",
                                                                                    reportType
                                                                                );
                                                                            }}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState2}
                                                                            onChange={onEditorStateChange2}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                            <Row className="field-test-section">
                                                                <div className="formGroup">
                                                                    <Form.Item
                                                                        label="Applicable Field Tests"
                                                                        name="app_field_togg"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked={
                                                                                props.isOpen.applicableField === true &&
                                                                                    props.values.app_field_togg === true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={props.handleChange(
                                                                                "app_field_togg"
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.applicableField === true &&
                                                                    props.values.app_field_togg === true ? (
                                                                    <div className="text-editor-wrapper">
                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                getSuggestion(
                                                                                    "applicable-field-tests",
                                                                                    reportType
                                                                                );
                                                                            }}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState3}
                                                                            onChange={onEditorStateChange3}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                            <Row className="industry-section">
                                                                <div className="formGroup">
                                                                    <Form.Item
                                                                        label="Industry Standards/Manufacturers Standards/Citations and Resources Cited"
                                                                        name="indus_stand_togg"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked={
                                                                                props.isOpen.industryField === true &&
                                                                                    props.values.indus_stand_togg === true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={props.handleChange(
                                                                                "indus_stand_togg"
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.industryField === true &&
                                                                    props.values.indus_stand_togg === true ? (
                                                                    <div className="text-editor-wrapper">
                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                getSuggestion(
                                                                                    "industry_standards",
                                                                                    reportType
                                                                                );
                                                                            }}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState4}
                                                                            onChange={onEditorStateChange4}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                            <Collapse
                                                                defaultActiveKey={
                                                                    props.values.stepFourTogg
                                                                        ? props.values.stepFourTogg
                                                                        : ["10"]
                                                                }
                                                                onChange={props.handleChange('stepFourTogg')}
                                                                className="reportCollapse"
                                                                expandIcon={({ isActive }) =>
                                                                    isActive ? (
                                                                        <MinusOutlined className="custom-icon" />
                                                                    ) : (
                                                                        <PlusOutlined className="custom-icon" />
                                                                    )
                                                                }
                                                                ghost
                                                            >
                                                                <Panel header="Manufacturing issues, Site related issues, Performance related issues and Installation related issues" key={10}>
                                                                    {props.values.disable_conclusion && (
                                                                        <div
                                                                            style={{
                                                                                backgroundColor: "#f8f9fa",
                                                                                borderLeft: "4px solid #0A497B",
                                                                                padding: "16px",
                                                                                borderRadius: "4px",
                                                                                fontFamily: "Arial, sans-serif",
                                                                                color: "#333",
                                                                                margin: "16px 0"
                                                                            }}
                                                                        >
                                                                            <p style={{ fontWeight: "bold", color: "#0A497B", marginBottom: "8px" }}>
                                                                                <strong>Note:</strong> For Mohawk claims, the sections for Manufacturing Issues, Site-Related Issues, Performance-Related Issues, Installation-Related Issues, Conclusion and Cause are currently disabled.
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <Row className="issues-panel-section">
                                                                        <div className="formGroup">
                                                                            <Form.Item
                                                                                label="Manufacturing issues"
                                                                                name="manufacturing_issue_togg"
                                                                            >
                                                                                <Switch
                                                                                    defaultChecked={
                                                                                        props.isOpen.manuissuesField ===
                                                                                            true &&
                                                                                            props.values.manufacturing_issue_togg === true
                                                                                            && props.values.disable_conclusion !== true
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    disabled={props.values.disable_conclusion}
                                                                                    onChange={props.handleChange(
                                                                                        "manufacturing_issue_togg"
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {props.isOpen.manuissuesField === true &&
                                                                            props.values.manufacturing_issue_togg === true ? (
                                                                            <div className="text-editor-wrapper">
                                                                                <SunEditor
                                                                                    onFocus={(event) => {
                                                                                        getSuggestion(
                                                                                            "manufacturing_issue",
                                                                                            reportType
                                                                                        );
                                                                                    }}
                                                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                                    setOptions={SunEditorOpts}
                                                                                    onImageUploadBefore={onImageUploadBefore}
                                                                                    setContents={editorState10}
                                                                                    onChange={onEditorStateChange10}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Row>
                                                                    <Row className="issues-panel-section">
                                                                        <div className="formGroup">
                                                                            <Form.Item
                                                                                label="Site related issues"
                                                                                name="site_rel_issue_togg"
                                                                            >
                                                                                <Switch
                                                                                    defaultChecked={
                                                                                        props.isOpen.siteissuesField ===
                                                                                            true &&
                                                                                            props.values.site_rel_issue_togg === true
                                                                                            ? true && props.values.disable_conclusion !== true
                                                                                            : false
                                                                                    }
                                                                                    disabled={props.values.disable_conclusion}
                                                                                    onChange={props.handleChange(
                                                                                        "site_rel_issue_togg"
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {props.isOpen.siteissuesField === true &&
                                                                            props.values.site_rel_issue_togg === true ? (
                                                                            <div className="text-editor-wrapper">
                                                                                <SunEditor
                                                                                    onFocus={(event) => {
                                                                                        getSuggestion(
                                                                                            "site_rel_issue",
                                                                                            reportType
                                                                                        );
                                                                                    }}
                                                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                                    setOptions={SunEditorOpts}
                                                                                    onImageUploadBefore={onImageUploadBefore}
                                                                                    setContents={editorState11}
                                                                                    onChange={onEditorStateChange11}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Row>
                                                                    <Row className="issues-panel-section">
                                                                        <div className="formGroup">
                                                                            <Form.Item
                                                                                label="Performance related issues"
                                                                                name="perfo_rel_issue_togg"
                                                                            >
                                                                                <Switch
                                                                                    defaultChecked={
                                                                                        props.isOpen.perfoissuesField ===
                                                                                            true &&
                                                                                            props.values.perfo_rel_issue_togg === true
                                                                                            && props.values.disable_conclusion !== true
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    disabled={props.values.disable_conclusion}
                                                                                    onChange={props.handleChange(
                                                                                        "perfo_rel_issue_togg"
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {props.isOpen.perfoissuesField === true &&
                                                                            props.values.perfo_rel_issue_togg === true ? (
                                                                            <div className="text-editor-wrapper">
                                                                                <SunEditor
                                                                                    onFocus={(event) => {
                                                                                        getSuggestion(
                                                                                            "perfo_rel_issue",
                                                                                            reportType
                                                                                        );
                                                                                    }}
                                                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                                    setOptions={SunEditorOpts}
                                                                                    onImageUploadBefore={onImageUploadBefore}
                                                                                    setContents={editorState12}
                                                                                    onChange={onEditorStateChange12}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Row>
                                                                    <Row className="issues-panel-section">
                                                                        <div className="formGroup">
                                                                            <Form.Item
                                                                                label="Installation related issues"
                                                                                name="insta_rel_issue_togg"
                                                                            >
                                                                                <Switch
                                                                                    defaultChecked={
                                                                                        props.isOpen.instaissuesField ===
                                                                                            true &&
                                                                                            props.values.insta_rel_issue_togg === true
                                                                                            && props.values.disable_conclusion !== true
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    disabled={props.values.disable_conclusion}
                                                                                    onChange={props.handleChange(
                                                                                        "insta_rel_issue_togg"
                                                                                    )}
                                                                                />
                                                                            </Form.Item>
                                                                        </div>
                                                                        {props.isOpen.instaissuesField === true &&
                                                                            props.values.insta_rel_issue_togg === true ? (
                                                                            <div className="text-editor-wrapper">
                                                                                <SunEditor
                                                                                    onFocus={(event) => {
                                                                                        getSuggestion(
                                                                                            "insta_rel_issue",
                                                                                            reportType
                                                                                        );
                                                                                    }}
                                                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                                    setOptions={SunEditorOpts}
                                                                                    onImageUploadBefore={onImageUploadBefore}
                                                                                    setContents={editorState13}
                                                                                    onChange={onEditorStateChange13}
                                                                                />
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </Row>
                                                                </Panel>

                                                            </Collapse>
                                                            <Row className="conclusion-section">
                                                                <div className="formGroup">
                                                                    <Form.Item
                                                                        label="Conclusion"
                                                                        name="conc_togg"
                                                                    >
                                                                        <Switch
                                                                            defaultChecked={
                                                                                props.isOpen.conclusionField === true &&
                                                                                    props.values.conc_togg === true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={props.handleChange("conc_togg")}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.conclusionField === true &&
                                                                    props.values.conc_togg === true ? (
                                                                    <div className="text-editor-wrapper">
                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                if (!props.values.disable_conclusion) {
                                                                                    getSuggestion('conclusion', reportType);
                                                                                }
                                                                            }}
                                                                            readOnly={props.values.disable_conclusion}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState5}
                                                                            onChange={props.values.disable_conclusion ? noAction : onEditorStateChange5}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                            <Row className="cause-section">
                                                                <div className="formGroup">
                                                                    <Form.Item label="Cause" name="cause_togg">
                                                                        <Switch
                                                                            defaultChecked={
                                                                                (props.values.cause_togg === true ||
                                                                                    props.isOpen.causeField === true) && props.values.disable_conclusion !== true
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            disabled={props.values.disable_conclusion}
                                                                            onChange={props.handleChange(
                                                                                "cause_togg"
                                                                            )}
                                                                        />
                                                                    </Form.Item>
                                                                </div>
                                                                {props.isOpen.causeField === true ||
                                                                    props.values.cause_togg === true ? (
                                                                    <div className="text-editor-wrapper">
                                                                        <SunEditor

                                                                            onFocus={(event) => {
                                                                                getSuggestion(
                                                                                    "cause",
                                                                                    reportType
                                                                                );
                                                                            }}
                                                                            setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                            setOptions={SunEditorOpts}
                                                                            onImageUploadBefore={onImageUploadBefore}
                                                                            setContents={editorState6}
                                                                            onChange={onEditorStateChange6}

                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Panel>

                                                <Panel
                                                    header="Confidential Information"
                                                    key={7}
                                                    className="confidential-panel"
                                                >
                                                    <Col xs={24} md={24} xl={24}>
                                                        <div className="formGroup">
                                                            <Form.Item
                                                                label="Has Confidential comments"
                                                                name="confi"
                                                            >
                                                                <Switch
                                                                    defaultChecked={
                                                                        props.values.confi === true ||
                                                                            props.isOpen.confidentialField === true
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={props.handleChange("confi")}
                                                                />
                                                            </Form.Item>
                                                        </div>
                                                        {props.isOpen.confidentialField === true ||
                                                            props.values.confi === true ? (
                                                            <div className="text-editor-wrapper">
                                                                <SunEditor

                                                                    onFocus={(event) => {
                                                                        getSuggestion(
                                                                            "confidential-information",
                                                                            reportType
                                                                        );
                                                                    }}
                                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                    setOptions={SunEditorOpts}
                                                                    onImageUploadBefore={onImageUploadBefore}
                                                                    setContents={editorState7}
                                                                    onChange={onEditorStateChange7}

                                                                />
                                                            </div>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Col>
                                                </Panel>
                                                <Panel header="Footer" key={8}>
                                                    <Col xs={24} md={24} xl={24}>
                                                        <Row
                                                            gutter={16}
                                                            className="footer-singnature-section"
                                                        >
                                                            <div className="formGroup">
                                                                <Form.Item
                                                                    label="Upload Signature"
                                                                    name="upload_sign_toggle"
                                                                >
                                                                    <Switch
                                                                        defaultChecked={
                                                                            props.values.upload_sign_toggle ===
                                                                                true ||
                                                                                props.isOpen.signatureField === true
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={props.handleChange(
                                                                            "upload_sign_toggle"
                                                                        )}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            {props.isOpen.signatureField === true ||
                                                                props.values.upload_sign_toggle === true ? (
                                                                <>
                                                                    <div className="image-wrapper">
                                                                        <Form.Item
                                                                            name="upload"
                                                                            valuePropName="fileList"
                                                                            getValueFromEvent={normFile}
                                                                        >
                                                                            <Upload
                                                                                name="avatar"
                                                                                listType="picture-card"
                                                                                className="avatar-uploader"
                                                                                showUploadList={false}
                                                                                customRequest={uploadImage}
                                                                                beforeUpload={beforeUpload}
                                                                                onChange={handleChangeImage}
                                                                            >
                                                                                {imageUrl ? (
                                                                                    <img
                                                                                        src={imageUrl}
                                                                                        alt="avatar"
                                                                                        style={{
                                                                                            width: "100%",
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    uploadButton
                                                                                )}
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                    </Col>
                                                    <Col xs={24} md={24} xl={24}>
                                                        <Row gutter={16}>
                                                            <div className="formGroup">
                                                                <Form.Item
                                                                    label="Footer Text"
                                                                    name="footer_text_info"
                                                                    valuePropName="checked"
                                                                >
                                                                    <Switch
                                                                        defaultChecked={
                                                                            props.values.footer_text_info === true ||
                                                                                props.isOpen.footerField === true
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        onChange={props.handleChange(
                                                                            "footer_text_info"
                                                                        )}
                                                                    />
                                                                </Form.Item>
                                                            </div>
                                                            {props.isOpen.footerField === true ||
                                                                props.values.footer_text_info === true ? (
                                                                <div className="text-editor-wrapper">
                                                                    <SunEditor

                                                                        onFocus={(event) => {
                                                                            getSuggestion(
                                                                                "footer-text",
                                                                                reportType
                                                                            );
                                                                        }}
                                                                        setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                                        setOptions={SunEditorOpts}
                                                                        onImageUploadBefore={onImageUploadBefore}
                                                                        setContents={editorState8}
                                                                        onChange={onEditorStateChange8}

                                                                    />
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Row>
                                                    </Col>
                                                </Panel>
                                            </Collapse>
                                        </Col>
                                        <Col xs={24} md={8} xl={8} className="sticySidebar">
                                            <Card className="custom-sidecard">
                                                <h5 className="formGroupTitle1">
                                                    Industry Standards and Citations
                                                </h5>
                                                <div className="searchWrapper">
                                                    <Input
                                                        prefix={<SearchOutlined />}
                                                        placeholder="Search for names.."
                                                        className="formControl"
                                                        value={search}
                                                        onChange={(e) => setSearch(e.target.value)}
                                                    />
                                                    <p>
                                                    Your citations will start appearing below when you click into any section of the report.
                                                    </p>
                                                    {loading ? (
                                                        <div className="spinDiv">
                                                            <SkeletonIndustryStandard />
                                                        </div>
                                                    ) : (
                                                        <div className="innerCardWrap">
                                                            {filterStandards && search
                                                                ? filterStandards?.map((item, index) => {
                                                                    return (
                                                                        <Card
                                                                            className="industryCard mb-2 sidebarCard"
                                                                            key={index}
                                                                        >
                                                                            <div className="industryCardBody">
                                                                                <div className="cardTopSection">
                                                                                    <div className="headerSec">
                                                                                        <h3
                                                                                            className="industryTitle"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: item.title,
                                                                                            }}
                                                                                        ></h3>
                                                                                        <p
                                                                                            className="industryDesc"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: item.excerpt,
                                                                                            }}
                                                                                        ></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="contentSec">
                                                                                    <div className="btnGroup">
                                                                                        <Tooltip title="Copy/Paste">
                                                                                            <Button
                                                                                                type="text"
                                                                                                id={item.id}
                                                                                                className="indstryBtn viewBtn"
                                                                                                onClick={() =>
                                                                                                    sendTextToEditor(
                                                                                                        item.description,
                                                                                                        item.label
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <CopyOutlined />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="Edit">
                                                                                            <Button
                                                                                                type="text"
                                                                                                id={item.id}
                                                                                                className="indstryBtn"
                                                                                            >
                                                                                                <FormOutlined />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        <Tooltip title="View More">
                                                                                            <Button
                                                                                                type="text"
                                                                                                id={item.id}
                                                                                                className="indstryBtn"
                                                                                                onClick={() =>
                                                                                                    handleClick(item.id)
                                                                                                }
                                                                                            >
                                                                                                <EyeOutlined />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Card>
                                                                    );
                                                                })
                                                                : sidebarState?.map((item, index) => {
                                                                    return (
                                                                        <Card
                                                                            className="industryCard mb-2 sidebarCard"
                                                                            key={index}
                                                                        >
                                                                            <div className="industryCardBody">
                                                                                <div className="cardTopSection">
                                                                                    <div className="headerSec">
                                                                                        <h3
                                                                                            className="industryTitle"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: item.title,
                                                                                            }}
                                                                                        ></h3>
                                                                                        <p
                                                                                            className="industryDesc"
                                                                                            dangerouslySetInnerHTML={{
                                                                                                __html: item.excerpt,
                                                                                            }}
                                                                                        ></p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="contentSec">
                                                                                    <div className="btnGroup">
                                                                                        <Tooltip title="Copy/Paste">
                                                                                            <Button
                                                                                                type="text"
                                                                                                id={item.id}
                                                                                                className="indstryBtn viewBtn"
                                                                                                onClick={() =>
                                                                                                    sendTextToEditor(
                                                                                                        item.description,
                                                                                                        item.label
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <CopyOutlined />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                        {/* <Tooltip title="Edit">
                                              <Button
                                                type="text"
                                                id={item.id}
                                                className="indstryBtn"
                                              >
                                                <FormOutlined />
                                              </Button>
                                            </Tooltip> */}
                                                                                        <Tooltip title="View More">
                                                                                            <Button
                                                                                                type="text"
                                                                                                id={item.id}
                                                                                                className="indstryBtn"
                                                                                                onClick={() =>
                                                                                                    handleClick(item.id)
                                                                                                }
                                                                                            >
                                                                                                <EyeOutlined />
                                                                                            </Button>
                                                                                        </Tooltip>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </Card>
                                                                    );
                                                                })}
                                                        </div>
                                                    )}
                                                </div>
                                            </Card>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="attachmentSection">
                                    <div className="attachmentHead">
                                        <h4 className="formGroupTitle">Add Attachments</h4>
                                    </div>
                                    <Row className="custom-dropzone-row" gutter={16}>
                                        <Upload1
                                            takefileList={takefileList}
                                            values={props.values}
                                            setLoadings={props.setLoadings}
                                        ></Upload1>
                                    </Row>
                                </div>
                                <div className="attachmentSection">
                                    <div className="CertificationHead">
                                        <h4 className="formGroupTitle">Certification</h4>
                                    </div>
                                    <Row className="my-2 footerRow" gutter={6}>
                                        {Object.keys(footerLogo).map((keyName, index) => (
                                            <Form.Item name={[`footerLogo`, index]} key={index}>
                                                <Col className="col-auto h-100" key={index}>
                                                    <div className="logoDiv">
                                                        <input
                                                            type="checkbox"
                                                            id={footerLogo[keyName].logo_id}
                                                            name="logoSelect[]"
                                                            defaultChecked={selectedlogs?.includes(
                                                                footerLogo[keyName].logo_id?.toString()
                                                            )}
                                                            onChange={props.handleChange("footerLogo")}
                                                            value={[footerLogo[keyName].logo_id]}
                                                        />

                                                        <label htmlFor={footerLogo[keyName].logo_id}>
                                                            <Image
                                                                src={footerLogo[keyName].logo_url}
                                                                className="img-fluid"
                                                                alt="logos"
                                                                preview={false}
                                                            />
                                                        </label>
                                                    </div>
                                                </Col>
                                            </Form.Item>
                                        ))}
                                    </Row>
                                </div>
                                <div className="attachmentSection">
                                    <div className="CertificationHead">
                                        <h4 className="formGroupTitle">Private Notes</h4>
                                    </div>
                                    <div className="p-3">
                                        <div className="formGroup">
                                            <Form.Item
                                                label="Private Notes"
                                                name="private_notes_togg"
                                            >
                                                <Switch
                                                    defaultChecked={
                                                        props.values.private_notes_togg === true ||
                                                            props.isOpen.notesField === true
                                                            ? true
                                                            : false
                                                    }
                                                    onChange={props.handleChange("private_notes_togg")}
                                                />
                                            </Form.Item>
                                        </div>
                                        {props.isOpen.notesField === true ||
                                            props.values.private_notes_togg === true ? (
                                            <div className="text-editor-wrapper">
                                                <SunEditor

                                                    onFocus={(event) => {
                                                        getSuggestion(
                                                            "private_notes",
                                                            reportType
                                                        );
                                                    }}
                                                    setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                                    setOptions={SunEditorOpts}
                                                    onImageUploadBefore={onImageUploadBefore}
                                                    setContents={editorState9}
                                                    onChange={onEditorStateChange9}

                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </Form >
            <Modal
                title={industryContent.title}
                centered
                open={modalIndustry}
                closable={false}
                className="inspectionCategoryModal industryModal"
                width={1000}
                footer={[
                    <Button
                        key="back"
                        onClick={() => setModalIndustry(false)}
                        className="submitBtn"
                    >
                        Ok
                    </Button>,
                ]}
            >
                <div className="modalContent">
                    <div className="formGroup">
                        <p
                            className="mcDescription"
                            dangerouslySetInnerHTML={{ __html: industryContent.description }}
                        ></p>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default CommonFourthStep;
