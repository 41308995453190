import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Upload,
  message,
  Menu,
  Dropdown,
  Row,
  Col,
  Image,
  Skeleton,
  Checkbox,
  Modal,
  Typography,
  Spin,
  Form,
} from "antd";
import {
  ArrowLeftOutlined,
  EllipsisOutlined,
  DeleteOutlined,
  EditOutlined,
  CaretDownOutlined,
  DownloadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import {
  getProject,
  deleteProject,
  deleteProjectImage,
  getProjectImage,
} from "../../services/projectServices";
import ButtonBar from "../../components/ButtonNavigation";
import ImageEditorModal from "./ImageEditorModal";
import { updateProjectImage } from "../../services/projectServices";
import axios from "axios";
import placeholderIMage from "../../assets/images/project-placeholder-image.jpg";
import { DndProvider } from "react-dnd";
import { LARAVEL_BACKEND_API_URL } from "../../Helper";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { HTML5Backend } from "react-dnd-html5-backend";
const ProjectDetail = () => {
  const [fileList, setFileList] = useState([]);
  const { Paragraph } = Typography;
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editorVisible, setEditorVisible] = useState(false);
  const [imageId, setImageId] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [dateImages, setDateImages] = useState([]);
  const [actionType, setActionType] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const [disabledImages, setDisabledImages] = useState([]);
  const [maxOrderIndex, setMaxOrderIndex] = useState(0);
  const [sortOrder, setSortOrder] = useState("asc"); // 'desc' for newest first, 'asc' for oldest first

  const queryParams = new URLSearchParams(window.location.search);
  const project_id = queryParams.get("project_id");
  const dispatch = useDispatch();

  const [avatarShape] = useState("circle");
  const navigate = useNavigate();
  const sortedObject = useMemo(() => {
	return Object.keys(dateImages)
	  .sort((a, b) => {
		const orderA = dateImages[a]?.order_index || 0;
		const orderB = dateImages[b]?.order_index || 0;
		return sortOrder === "desc" ? orderB - orderA : orderA - orderB;
	  })
	  .reduce((obj, key) => {
		obj[key] = dateImages[key];
		return obj;
	  }, {});
  }, [dateImages, sortOrder]);
  
  const restructureData = (data) => {
    const result = {};
    
    // Sort by order_index according to the current sortOrder
    data.sort((a, b) => {
      return sortOrder === 'desc' 
        ? b.order_index - a.order_index  // For descending order (newest first)
        : a.order_index - b.order_index; // For ascending order (oldest first)
    });
    
    data.forEach((item) => {
      const date = new Date(item.captured_at);
      const dayOfWeek = date.toLocaleString("default", { weekday: "long" });
      const monthName = date.toLocaleString("default", { month: "long" });
      const day = date.getDate();
      const year = date.getFullYear();
      const formattedDate = `${dayOfWeek}, ${monthName} ${day}${getDaySuffix(day)}, ${year}`;
  
      if (!result[formattedDate]) {
        result[formattedDate] = [];
      }
      result[formattedDate].push({ 
        id: item?.id, 
        image: item.image_url, 
        description: item?.description,
        order_index: item?.order_index || 0
      });
    });
  
    return result;
  };
  const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const fetchProject = () => {
    dispatch(getProject(project_id))
      .then((response) => {
        setProject(response);
        
        // Find the maximum order_index from existing images
        let maxIndex = 0;
        if (response?.inspection_images && response.inspection_images.length > 0) {
          maxIndex = response.inspection_images.reduce((max, img) => {
            return Math.max(max, img.order_index || 0);
          }, 0);
        }
        setMaxOrderIndex(maxIndex);
        
        setDateImages(restructureData(response?.inspection_images));
      })
      .catch((error) => {
        message.error('Failed to fetch project');
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchProject(); // eslint-disable-next-line
  }, [project_id]); 
  useEffect(() => {
	if (project?.inspection_images) {
	  setDateImages(restructureData(project.inspection_images));
	} // eslint-disable-next-line
  }, [sortOrder, project]);
  if (loading) {
    return (
      <div className="p-6">
        <div className="container-xxl">
          <div className="Skeleton_cover project-detail-skeleton">
            <Row
              gutter={16}
              className="d-flex align-items-center justify-content-between"
              style={{
                borderBottom: "1px solid #f0f0f0",
                paddingBottom: "10px",
                marginBottom: "20px",
              }}
            >
              <Col
                xs={12}
                md={12}
                xl={12}
                className="sklpad  d-flex align-items-center justify-content-start"
              >
                <Skeleton.Input
                  className="mb-1"
                  active
                  size="default"
                  style={{
                    height: "38px",
                    width: "200px",
                    borderRadius: "8px",
                  }}
                />
              </Col>
              <Col
                xs={12}
                md={12}
                xl={12}
                className="sklpad d-flex align-items-center justify-content-end"
              >
                <Skeleton.Avatar active size="default" shape={avatarShape} />
              </Col>
            </Row>
            <Row
              gutter={16}
              className="d-flex align-items-center justify-content-md-start"
              style={{
                borderBottom: "1px solid #f0f0f0",
                paddingBottom: "20px",
                marginBottom: "20px",
              }}
            >
              <Col
                xs={24}
                md={8}
                xl={6}
                className="sklpad d-flex align-items-center justify-content-start"
              >
                <Skeleton.Image
                  className="pro-detail-skeleton-large-img"
                  active
                  size="large"
                  style={{ borderRadius: "8px", marginBottom: "20px" }}
                />
              </Col>
              <Col xs={24} md={16} xl={18} className="sklpad">
                <Skeleton.Input
                  className="mb-1"
                  block={true}
                  active
                  size="default"
                  style={{ height: "22px" }}
                />
                <Skeleton.Input
                  className="mb-1"
                  block={true}
                  active
                  size="large"
                  style={{ height: "26px" }}
                />
                <Skeleton.Input
                  className="mb-1"
                  block={true}
                  active
                  size="default"
                  style={{ height: "18px" }}
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: "30px" }}
            >
              <Col
                xs={12}
                md={12}
                xl={12}
                className="sklpad  d-flex align-items-center justify-content-start"
              ></Col>
              <Col
                xs={12}
                md={12}
                xl={12}
                className="sklpad d-flex align-items-center justify-content-end"
              >
                <Skeleton.Input
                  className="mb-1 mx-1"
                  active
                  size="default"
                  style={{
                    height: "42px",
                    width: "150px",
                    borderRadius: "8px",
                  }}
                />
              </Col>
            </Row>
            <Row
              gutter={16}
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: "30px" }}
            >
              <Col
                xs={24}
                md={24}
                xl={24}
                className="sklpad d-flex align-items-center"
              >
                <Skeleton.Input
                  className="mb-1 mx-1"
                  block={true}
                  active
                  size="default"
                  style={{ height: "142px", borderRadius: "8px" }}
                />
              </Col>
            </Row>
            <div className="mb-5">
              {Array.from({ length: 2 }).map((_, i) => (
                <div key={i}>
                  <Row className="mt-3 project-detail-images-skeleton">
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      sm={8}
                      md={6}
                      xl={4}
                      className="sklpad mb-2 px-2"
                    >
                      <Skeleton.Image
                        active
                        size="large"
                        style={{ borderRadius: "8px" }}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleChangedescription = (value, org_value, image_id) => {
    if (value === org_value) {
      return false;
    } else {
      let formData = {
        project_id,
        description: value,
      };

      setDateImages((prevDateImages) => {
        const updatedImages = { ...prevDateImages };
        for (const date in updatedImages) {
          const imageToUpdate = updatedImages[date].find(
            (image) => image.id === image_id
          );
          if (imageToUpdate) {
            imageToUpdate.description = value;
            break;
          }
        }
        return updatedImages;
      });

      dispatch(updateProjectImage(project_id, image_id, formData))
        .then((res) => {
          message.success("Description updated successfully!");
        })
        .catch((error) => {
          console.error({ error });
        });
    }
  };

  const handleEditImage = (image) => {
    const imageObj = new window.Image();
    imageObj.crossOrigin = "Anonymous";
    imageObj.src = image.image + "?not-from-cache-please";
    setSelectedImage(imageObj.src);
    setImageId(image.id);
    setEditorVisible(true);
  };

  const handleModalClose = () => {
    setSelectedImage(null);
    setImageId(null);
    setEditorVisible(false);
  };

  if (!project) return null;
  const handleDelete = (projectId) => {
    dispatch(deleteProject(projectId))
      .then(() => {
        message.success("Project deleted successfully");
        navigate("/projects");
      })
      .catch((error) => {
        message.error("Failed to delete project");
      });
  };

  const confirmDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this project?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(project?.id);
      }
    });
  };

  const handleMenuClick = (e) => {
    if (e.key === "edit") {
      navigate(`/project-edit/?project_id=${project?.id}`);
    } else if (e.key === "delete") {
      confirmDelete();
    }
  };

  const deleteImagesInSequence = (selectedImages, projectId) => {
    if (!selectedImages || !projectId) return Promise.resolve();

    return selectedImages.reduce((promise, image) => {
      return promise.then(() => {
        return dispatch(deleteProjectImage(projectId, image))
          .then((res) => {})
          .catch((error) => {
            console.error(error);
          })
          .finally(() => {
            setDisabledImages([]);
          });
      });
    }, Promise.resolve());
  };

  const downloadImagesAsZip = async () => {
    const zip = new JSZip();
    const folder = zip.folder("project_images");
    const promises = [];

    for (const image of selectedImages) {
      const promise = dispatch(getProjectImage(project?.id, image))
        .then((res) => {
          if (!res?.image_url) throw new Error("Image URL not found");
          return fetch(`${res.image_url}?cacheBust=${Date.now()}`)
        })
        .then((response) => {
          if (!response.ok) throw new Error("Failed to fetch image");
          return response.blob();
        })
        .then((blob) => {
          folder.file(`${image}.jpg`, blob);
        })
        .catch((error) => {
          console.error("Error processing image:", image, error);
        });

      promises.push(promise);
    }
    await Promise.all(promises);

    zip
      .generateAsync({ type: "blob" })
      .then((content) => {
        saveAs(content, "project_images.zip");
      })
      .catch((error) => {
        console.error("Error generating ZIP file:", error);
      })
      .finally(() => {
        setActionLoading(false);
        setActionType(null);
      });
  };

  const handleMenuImagesClick = (e) => {
    setActionLoading(true);
    if (selectedImages?.length > 0) {
      if (e?.key === "delete-images") {
        setActionType("Deleting...");

        setDisabledImages([...selectedImages]);

        deleteImagesInSequence(selectedImages, project?.id)
          .then(() => {
            setSelectedImages([]);
            fetchProject();
          })
          .catch((error) => {
            console.error("Error deleting images:", error);
          })
          .finally(() => {
            message.success(
              selectedImages?.length > 1
                ? "Images deleted successfully!"
                : "Image deleted successfully!"
            );
            setActionLoading(false);
            setActionType(null);
          });
      } else {
        setActionType("Downloading...");
        downloadImagesAsZip();
      }
    }
  };

  const menu = (
    <Menu
      mode="horizontal"
      className="menu-single-project"
      onClick={handleMenuClick}
    >
      <Menu.Item key="edit">
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item key="delete">
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  );
  const menuImages = (
    <Menu
      mode="horizontal"
      className="menu-single-project"
      onClick={handleMenuImagesClick}
    >
      <Menu.Item key="download-images" disabled={selectedImages?.length === 0}>
        <DownloadOutlined /> Download
      </Menu.Item>
      <Menu.Item key="delete-images" disabled={selectedImages?.length === 0}>
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  );

  const renderImagesByDate = (imagesByDate) => {
    const handleCheckboxChange = (ids, isChecked) => {
      setSelectedImages((prevSelectedImages) => {
        const updatedSelectedImages = [...prevSelectedImages];

        ids.forEach((image) => {
          if (isChecked) {
            if (!updatedSelectedImages.includes(image.id)) {
              updatedSelectedImages.push(image.id);
            }
          } else {
            const index = updatedSelectedImages.indexOf(image.id);
            if (index !== -1) {
              updatedSelectedImages.splice(index, 1);
            }
          }
        });

        return updatedSelectedImages;
      });
    };

    const isAllChecked = (images) =>
      images.every((image) => selectedImages.includes(image.id));

    const isIndeterminate = (images) =>
      images.some((image) => selectedImages.includes(image.id)) &&
      !isAllChecked(images);

    return (
      <Row gutter={[16, 16]} className="mt-4 project-detail-bottom-row">
        {Object.entries(imagesByDate)?.map(([date, images]) => (
          <div className="w-100 mt-3" key={images[0]?.id}>
            <Checkbox
              className="main-project-checkbox"
              onChange={(e) => handleCheckboxChange(images, e.target.checked)}
              checked={isAllChecked(images)}
              indeterminate={isIndeterminate(images)}
            >
              <div className="single-project-date-time">{date}</div>
            </Checkbox>

            <Image.PreviewGroup>
              <Row gutter={[16, 16]}>
                {images?.map((image, index) => (
                  <Col
                    className="single-project-date-image"
                    key={index}
                    xs={12}
                    sm={8}
                    md={6}
                    lg={4}
                  >
                    <div className="project-detail-image-card">
                      <Checkbox
                        disabled={disabledImages.includes(image.id)}
                        onChange={(e) =>
                          handleCheckboxChange(
                            [{ id: image?.id }],
                            e.target.checked
                          )
                        }
                        checked={selectedImages?.includes(image?.id)}
                      />
                      <Image
                        src={image.image}
                        alt={`Image for ${date} - ${index + 1}`}
                        className="object-cover image-date-project"
                        preview={{
                          src: image.image,
                        }}
                      />
                      <div className="edit-button-wrapper">
                        <Button
                          disabled={disabledImages.includes(image.id)}
                          icon={<EditOutlined />}
                          onClick={() => handleEditImage(image)}
                          ghost
                        ></Button>
                      </div>
                      <Paragraph
                        disabled={disabledImages.includes(image.id)}
                        className="project-detail-image-description"
                        editable={{
                          icon: <EditOutlined />,
                          tooltip: "Click to edit description",
                          onChange: (e) => {
                            handleChangedescription(
                              e,
                              image.description,
                              image?.id
                            );
                          },
                          enterIcon: null,
                        }}
                      >
                        {image.description}
                      </Paragraph>
                    </div>
                  </Col>
                ))}
              </Row>
            </Image.PreviewGroup>
          </div>
        ))}
      </Row>
    );
  };

  const progress = {
    strokeWidth: 15,
    format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
  };

  const handlePreview = async (file) => {
    if (file.originFileObj) {
      setLoading(true);
      setLoading(false);
    }
  };

  const onChange = (info) => {
    if (info.file.status === 'uploading') {
      // When a file starts uploading, assign it an order_index
      const newFileList = [...info.fileList];
      const fileIndex = newFileList.findIndex(file => file.uid === info.file.uid);
      
      if (fileIndex !== -1 && !newFileList[fileIndex].order_index) {
        newFileList[fileIndex].order_index = maxOrderIndex + fileIndex + 1;
      }
      
      setFileList(newFileList);
    }
    if (info.file.status === 'removed') {
      setFileList(info.fileList);
    }
    if (info.file.status === 'done') {
      let newFileList = [...fileList];
      let index = newFileList.findIndex((ele) => ele.uid === info.file.uid);
      newFileList[index]['status'] = 'done';
      newFileList[index]['media_id'] = info.file.response.data.id;
      newFileList[index]['url'] = info.file.response.data.source_url;
      
      setFileList(newFileList);
      setUploadCount(prevCount => prevCount + 1);
      const fileInfo = info?.fileList?.filter((item) => item?.type !== "" && !item?.type?.includes("document") && !item?.type?.includes("video"))
      if (uploadCount + 1 === fileInfo.length) {
        // Update the maxOrderIndex for the next batch
        setMaxOrderIndex(maxOrderIndex + fileInfo.length);
        setUploadCount(0);
        setFileList([]);
        message.success('All images uploaded successfully');
        fetchProject();
      }
    }
  };

  const beforeUpload = (file) => {
    const isImage = file.type.startsWith("image/");

    if (!isImage) {
      message.error(
        "You can only upload image files (heic, heif, document and videos not supported)!"
      );
    }
    return isImage;
  };
  const uploadImage = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    
    // Find the file in fileList to get its assigned order_index
    const fileItem = fileList.find(item => item.uid === file.uid);
    // Use the order_index that was assigned in the onChange function
    const orderIndex = fileItem?.order_index || (maxOrderIndex + 1);
    
    fmData.append('image', file);
    fmData.append('project_id', project_id);
    fmData.append('order_index', orderIndex);
    
    try {
      const res = await axios.post(`${LARAVEL_BACKEND_API_URL}projects/${project_id}/images`, fmData, config);
      onSuccess(res);
    } catch (err) {
      console.log('Error: ', err);
      const error = new Error('Some error');
      onError({ err });
      console.warn(error);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e && e.fileList;
    }

    return e && e.fileList;
  };

  return (
    <div className="p-6">
      <div className="container-xxl">
        <div className="button-navigations mt-5">
          <ButtonBar />
        </div>
      </div>
      <>
        <div className="project-detail-section container-xxl mb-5">
          <div className="project-detail-project-header">
            <div className="back-button">
              <div className="row">
                <div className="col-6">
                  <Button
                    className="button-back"
                    type="button"
                    onClick={() => navigate(`/projects/`)}
                  >
                    <ArrowLeftOutlined />
                    Back to Projects
                  </Button>
                </div>
                <div className="col-6 text-end">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      className="single-project-menu-btn"
                      icon={<EllipsisOutlined />}
                    ></Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="row align-items-center project-detail-top-row">
              <div className="col-lg-3 col-md-4">
                <div className="pro-detail-header-image">
                  {project?.inspection_images?.length > 0 ? (
                    <img
                    className="pro-folder-img"
                    alt="folder-image"
                    src={project?.inspection_images?.[0]?.image_url}
                  />
                  ) : (
                    <img
                      className="pro-folder-img-icon"
                      alt="folder-image"
                      src={placeholderIMage}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-9 col-md-8">
                <h3 className="single-detail-project-heading">
                  {project?.name}
                </h3>
                <div className="project-address-description">
                  <p className="address">
                    {project?.address}, {project?.city}, {project?.state},{" "}
                    {project?.postal_code}
                  </p>
                  <p className="descriptions">
                    {project?.description || "No description provided"}
                  </p>
                  <p className="created-at">
                    Created At:-{" "}
                    {new Date(project?.created_at).toLocaleString()}
                  </p>
                  <p className="updated-at">
                    Updated At:-{" "}
                    {new Date(project?.updated_at).toLocaleString()}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
              <Button 
      className='sort-order-btn d-none' 
      onClick={() => setSortOrder(prev => {
        console.log("Changing sort order from", prev, "to", prev === 'desc' ? 'asc' : 'desc');
        return prev === 'desc' ? 'asc' : 'desc';
      })}
    >
      {sortOrder === 'desc' ? (
        <>Newest Uploads <ArrowDownOutlined /></>
      ) : (
        <>Oldest Uploads <ArrowUpOutlined /></>
      )}
    </Button>
              </div>
              <div className="col-md-6 text-right">
                <div className="project-upload-img-btn text-right">
             
                  <Dropdown overlay={menuImages} trigger={["click"]}>
                    <Button className="single-project-menu-btn-action">
                      {actionType !== null && actionLoading ? (
                        <>
                          {actionType} <Spin />
                        </>
                      ) : (
                        <>
                          Actions
                          <CaretDownOutlined />
                        </>
                      )}
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 text-right">
                <div
                  className="project-upload-img-btn text-right pt-0 mt-0"
                  style={{ border: "none" }}
                >
                  <>
                    <DndProvider backend={HTML5Backend}>
                      <Form.Item
                        name="dragger"
                        valuePropName={fileList}
                        getValueFromEvent={normFile}
                        noStyle
                      >
                        <Upload
                          name="files"
                          multiple={true}
                          accept="image/*"
                          beforeUpload={beforeUpload}
                          customRequest={uploadImage}
                          listType="picture-card"
                          fileList={fileList}
                          onChange={onChange}
                          onPreview={handlePreview}
                          progress={progress}
                        >
                          <p className="file-image-drag-btn">
                            Drop or Upload Photos Only Here (Videos Not
                            Supported)
                          </p>
                        </Upload>
                      </Form.Item>
                    </DndProvider>
                  </>
                </div>
              </div>
            </div>
            {renderImagesByDate(sortedObject)}
          </div>
        </div>
        <Modal
          className="project-image-editor-model"
          closable={false}
          footer={false}
          centered
          width="100%"
          title="Image Edit"
          onCancel={() => handleModalClose()}
          onOk={() => handleModalClose()}
          bodyStyle={{ height: "93vh", overflow: "hidden" }}
          open={editorVisible}
          style={{ top: 0, padding: 0 }}
        >
          <ImageEditorModal
            key={editorVisible ? Math.random() : null}
            imageUrl={selectedImage}
            project_id={project_id}
            image_id={imageId}
            setEditorVisible={setEditorVisible}
            setImageId={setImageId}
            setSelectedImage={setSelectedImage}
            fetchProject={fetchProject}
            editorVisible={editorVisible}
          />
        </Modal>
      </>
    </div>
  );
};
export default ProjectDetail;
