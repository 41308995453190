import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { useSelector } from 'react-redux';
import {
  AppstoreOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FolderOpenOutlined,
  FileTextOutlined,
  UserOutlined,
  FileUnknownOutlined,
  SolutionOutlined,
  ProjectOutlined
} from '@ant-design/icons';

const createMenuItems = () => ({
  manufacturer: [
    {
      label: 'Inspections',
      key: 'inspections',
      icon: <FileSearchOutlined />,
      children: [
        {
          label: 'Open Inspections',
          key: 'open-inspections',
          icon: <FileSearchOutlined />,
        },
        {
          label: 'Archived Claims',
          key: 'archived-claims',
          icon: <FolderOpenOutlined />,
        },
        {
          label: 'UnAssigned Claims',
          key: 'unassigned-claims',
          icon: <FileUnknownOutlined />,
          requiresMohawk: true,
        },
        {
          label: 'Field Services',
          key: 'field-services',
          icon: <SolutionOutlined />,
          requiresMohawk: true,
        },
      ],
    },
    {
      label: 'Claim Analysts',
      key: 'claim-analysts',
      icon: <UserOutlined />,
    },
    {
      label: 'Distributors or Dealers',
      key: 'dealers',
      icon: <AppstoreOutlined />,
    },
    {
      label: 'Industry Standards',
      key: 'industry-standards',
      icon: <FileTextOutlined />,
    },
  ],
  'claim-analyst': [
    {
      label: 'Inspections',
      key: 'inspections',
      icon: <FileSearchOutlined />,
      children: [
        {
          label: 'All Claims',
          key: 'all-claims',
          icon: <FileSearchOutlined />,
        },
        {
          label: 'Archived Claims',
          key: 'archived-claims',
          icon: <FolderOpenOutlined />,
        },
        {
          label: 'UnAssigned Claims',
          key: 'unassigned-claims',
          icon: <UserOutlined />,
          requiresMohawk: true,
        },
        {
          label: 'Field Services',
          key: 'field-services',
          icon: <SolutionOutlined />,
          requiresMohawk: true,
        },
      ],
    },
    {
      label: 'Industry Standards',
      key: 'industry-standards',
      icon: <FileTextOutlined />,
    },
  ],
  inspector: [
    {
      label: 'Inspections',
      key: 'inspections',
      icon: <FileSearchOutlined />,
      children: [
        {
          label: 'Open Inspections',
          key: 'open-inspections',
          icon: <FileSearchOutlined />,
        },
        {
          label: 'Completed Reports',
          key: 'completed-reports',
          icon: <FileDoneOutlined />,
        },
      ],
    },
    {
      label: 'All Clients',
      key: 'all-clients',
      icon: <AppstoreOutlined />,
      requiresFullAccess: true,
    },
    {
      label: 'All Invoices',
      key: 'all-invoices',
      icon: <AppstoreOutlined />,
      requiresFullAccess: true,
    },
    {
      label: 'Industry Standards',
      key: 'industry-standards',
      icon: <FileTextOutlined />,
      requiresFullAccess: true,
    },
    {
      label: 'Projects',
      key: 'projects',
      icon: <ProjectOutlined />,
      requiresAdmin: false,  // New condition for admin-only access
    },
  ],
});

const PATH_TO_KEY_MAP = {
  '/inspection-reports': 'open-inspections',
  '/inspection-completed-reports': 'completed-reports',
  '/claim-analyst-panel': 'all-claims',
  '/archived': 'archived-claims',
  '/unassigned-claims': 'unassigned-claims',
  '/field-services': 'field-services',
  '/AllClaimAnalysts': 'claim-analysts',
  '/dealers': 'dealers',
  '/industry-standards': 'industry-standards',
  '/all-clients': 'all-clients',
  '/all-invoices': 'all-invoices',
  '/projects': 'projects',
};

const getMenuItems = (role, userDetails) => {
  const { is_mohawk, is_admin, limited_access } = userDetails;
  const roleKey = role?.replace('um_', '') || '';
  const menuItems = createMenuItems();

  const filterMenuItems = (items) => {
    return items.map(item => ({
      ...item,
      disabled: (
        (item.requiresFullAccess && limited_access) ||
        (item.requiresAdmin && !is_admin)
      ),
      children: item.children?.filter(child =>
        !child.requiresMohawk || (child.requiresMohawk && is_mohawk)
      ),
    })).filter(item => !item.requiresAdmin || (item.requiresAdmin && is_admin));
  };

  return filterMenuItems(menuItems[roleKey] || []);
};

const ButtonNavigation = () => {
  const [current, setCurrent] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const userDetails = useSelector((state) => state.User.userDetails);

  useEffect(() => {
    const currentPath = location.pathname;
    setCurrent(PATH_TO_KEY_MAP[currentPath] || '');
  }, [location.pathname]);

  const onClick = (e) => {
    const path = Object.entries(PATH_TO_KEY_MAP).find(([_, value]) => value === e.key)?.[0];
    if (path) {
      setCurrent(e.key);
      navigate(path);
    }
  };

  const items = getMenuItems(userDetails?.user_role, userDetails);

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
      style={{ fontSize: '1rem' }}
    />
  );
};

export default ButtonNavigation;