import React from "react";
import { Badge, Tooltip } from "antd";

const LimitedAccessBadge = ({ isLimitedAccess }) => {
    return (
        isLimitedAccess && (
            <Tooltip title="Some features are restricted. Upgrade to Ultimate for full access!">
                <Badge count="Limited Access" style={{ backgroundColor: "#f5222d", color: "#fff", marginRight: '16px' }} />
            </Tooltip>
        )
    );
};

export default LimitedAccessBadge;
