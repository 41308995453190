import React, { useCallback, useRef, useState, useEffect } from 'react';
import 'antd/dist/antd.min.css';
import './upload.css';
import {
  Tooltip,
  Upload,
  Modal,
  Input,
  Radio,
  Typography,
  Form,
  message,
  Image,
  Spin,
  Row,
  Col,
  Button,
  Result,
  Checkbox,
  Skeleton
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import update from 'immutability-helper';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import axios from 'axios';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ImageEditor from './ImageEditor';
import { MEDIA_UPLOAD_URL, INSPECTION_URL } from '../../Helper';
import Swal from 'sweetalert2';
import Fancybox from './fancybox';
import { EyeOutlined, SmileOutlined } from '@ant-design/icons';
import * as getCompanycamPhotosService from '../../services/CompanyCam';
import * as getCompanycamProPhotosService from '../../services/CompnyCamImgs';
import * as companyCamtokenSave from '../../services/companyCamtokenService';
import CustomSpinner from '../../components/spinner';
import { useDispatch } from 'react-redux';
import { getProjects } from '../../services/projectServices';
import FolderIMage from "../../assets/images/blue-directory-icon.png";
const { Item } = Form;
const type = 'DragableUploadList';
const { Title } = Typography;

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = useRef(null);

  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};

      if (dragIndex === index) {
        return {};
      }

      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: {
      index,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  const errorNode = (
    <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>
  );
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''
        }`}
      style={{
        cursor: 'move',
      }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};
const progress = {
  strokeWidth: 15,
  format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
};

const App = (props) => {
  const [projects, setProjects] = useState([]);
  const takefileList = props.takefileList;
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState('');
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const handleCancel = () => setPreviewVisible(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFDCamModalOpen, setIsFDCamModalOpen] = useState(false);
  const [companyCam, setCompanycam] = useState([]);
  const [companyCamProImg, setCompanycamProImg] = useState([]);
  const [companyCamHeading, setCompanycamHeading] = useState('');
  const [CompanycamAddressCity, setCompanycamAddressCity] = useState('');
  const [CompanycamAddressPostal, setCompanycamAddressPostal] = useState('');
  const [CompanycamAddressState, setCompanycamAddressState] = useState('');
  const [CompanycamAddressStreet, setCompanycamAddressStreet] = useState('');
  const [selectedImages, setselectedImages] = useState([]);
  const [active, setActive] = useState(null);
  const [camLoader, setCamLoader] = useState(false);
  const [uploading, setuploading] = useState(false);
  const [cCprogress, setCcprogress] = useState(0);
  const [fDCamImages, setFDCamImages] = useState([]);
  const [selectImagesFDCam, setSelectImagesFDCam] = useState(false);
  const [fdCamLoader, setFdCamLoader] = useState(false)
  const [fdCamSelectedProject, setfdCamSelectedProjects] = useState()
  const [companyCamToken, setcompanyCamToken] = useState('');
  const [hideFdcamButton, setHideFdcamButton] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const sidebarRef = useRef(null);

  // Token Modal
  const [isModalToken, setIsModalToken] = useState(false);
  const [isModalHelp, setIsModalHelp] = useState(false);
  const [form1] = Form.useForm();
  const [form2] = Form.useForm();
  const [fdForm] = Form.useForm();
  var dispatch = useDispatch();
  const handleChange = (input) => (e) => {
    props.values.Allimagedescription = {
      ...props.values.Allimagedescription,
      [input]: {
        caption: e.target.value,
      },
    };
  };
  const normFile = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e && e.fileList;
    }

    return e && e.fileList;
  };
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );
  const fetchCompanycam = (params = {}) => {
    dispatch(getCompanycamPhotosService.getCompanycamPhotos())
      .then((res) => {
        setCompanycam(res);
      })
      .catch((err) => {
        setIsModalToken(true);
        setIsModalOpen(false);
      });
  };
  // Inside the ImageGallery component

  const setCompanycamToken = (value) => {
    dispatch(companyCamtokenSave.companyCamtokenService(value.companycam_token))
      .then((res) => {
        if (res.data.companycam_token === 'invalid') {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'You have entered invalid token!',
            confirmButtonText: 'Help',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              showHelpModal();
            }
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Your token have been successfully saved!',
          });
          handleCancel();

          let apiResponseCCToken = res.data.companycam_token;
          setcompanyCamToken(apiResponseCCToken);
          fetchCompanycam();
          localStorage.setItem('companycam_token', apiResponseCCToken);
        }
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCompImgs = companyCamProImg.map((c) => c?.uris[3]?.uri);
      setselectedImages(allCompImgs);
    } else {
      setselectedImages([]);
    }
  };

  async function saveCompanyCamMedia() {
    setuploading(true);
    const values = [];
    const keys = selectedImages;
    const key1 = 'url';
    const key2 = 'media_id';
    const key3 = 'status';
    const numOfimg = selectedImages.length;
    for (let i = 0; i < selectedImages.length; i++) {
      const url = selectedImages[i];

      try {
        const response = await axios.post(
          INSPECTION_URL + 'uploadMediafromCompanycam/',
          { url }
        );
        values[i] = response.data;
        if (numOfimg > 1) {
          let remainingImgs = numOfimg - (i + 1);
          let progressPercent = ((numOfimg - remainingImgs) / numOfimg) * 100;
          setCcprogress(progressPercent);
        } else {
          setCcprogress(0);
        }
      } catch (error) {
        console.error(`Error sending URL ${url} to API: ${error.message}`);
      }
    }

    const result = keys.reduce((acc, key, index) => {
      acc.push({
        [key1]: key,
        [key2]: values[index],
        [key3]: 'done',
        uid: values[index],
      });
      return acc;
    }, []);
    console.warn({ result });
    setFileList([...fileList, ...result]);
    setuploading(false);
    setselectedImages([]);
    setIsModalOpen(false);
    setCcprogress(0);
  }
  function saveFDCamMedia() {
    setuploading(true);
    const result = fDCamImages.map((image) => ({
      url: image.url,
      media_id: image.id,  // Placeholder media_id for demonstration
      status: 'done',
      uid: `${image.id}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
      name: `image_${image?.id}_${Date.now()}`,
      id: image.id,
      isFdcam: true,
      desc: image.description
    }));
    props.values.Allimagedescription = {
      ...props.values.Allimagedescription,
      ...Object.fromEntries(result.map((image) => [image.uid, { caption: image.desc }])),
    }
    console.info(props.values.Allimagedescription,fDCamImages)
    setFileList(prevFileList => [...prevFileList, ...result]);
    setuploading(false);
    setFDCamImages([]);
    setIsFDCamModalOpen(false);
    setCcprogress(0);
  }

  const handleButtonClick = () => {
    const externalUrl = 'https://app.companycam.com/access_tokens';
    // Open the URL in a new tab
    window.open(externalUrl, '_blank');
  };

  const fetchCompanycamProImg = (project_id, index) => {
    console.info({ index });
    setActive(index);
    setCamLoader(true);
    dispatch(
      getCompanycamProPhotosService.getCompanycamProjectPhotos(project_id)
    )
      .then((res) => {
        var selectedProject = companyCam.filter(function (el) {
          return el.id === project_id;
        });
        setCompanycamHeading(selectedProject[0]?.name);
        setCompanycamAddressStreet(
          selectedProject[0]?.address['street_address_1']
        );
        setCompanycamAddressCity(selectedProject[0]?.address['city']);
        setCompanycamAddressState(selectedProject[0]?.address['state']);
        setCompanycamAddressPostal(selectedProject[0]?.address['postal_code']);

        // To sort images based on ID
        const sortedImages = res.sort((a, b) => a.id - b.id);
        setCompanycamProImg(sortedImages);

        setCamLoader(false);
      })
      .catch((err) => {
        console.info('Get Error Edit State...');
      });
  };

  const hadleChangeCompanycam = (e) => {
    let companyCamimgs = [];
    let url = e.target.value;
    companyCamimgs = [...selectedImages];
    if (companyCamimgs?.includes(url)) {
      let deselectedUrl = companyCamimgs.indexOf(url);
      delete companyCamimgs[deselectedUrl];
    } else {
      companyCamimgs.push(url);
    }
    companyCamimgs = companyCamimgs?.filter((e) => e);
    setselectedImages(companyCamimgs);
  };
  useEffect(() => {
    setFileList(props.values.attachments ? props.values.attachments : []);
    let localCCTokenItem = localStorage.getItem('companycam_token');
    if (localCCTokenItem) {
      setcompanyCamToken(localCCTokenItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values.attachments]);

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/gif' ||
      file.type === 'image/jpg' ||
      file.type === 'image/heic';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    return isJpgOrPng;
  };
  const uploadImage = async (options, values) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append('file', file);
    try {
      const res = await axios.post(MEDIA_UPLOAD_URL, fmData, config);
      onSuccess(res);
    } catch (err) {
      console.log('Eroor: ', err);
      const error = new Error('Some error');
      onError({ err });
      console.error(error);
    }
  };

  const onChange = (info) => {
    if (info.file.status === 'uploading') {
      setFileList(info.fileList);
    }
    if (info.file.status === 'removed') {
      setFileList(info.fileList);
    }
    if (info.file.status === 'done') {
      let newFileList = [...fileList];
      let index = newFileList.findIndex((ele) => ele.uid === info.file.uid);
      newFileList[index]['status'] = 'done';
      newFileList[index]['media_id'] = info.file.response.data.id;
      newFileList[index]['url'] = info.file.response.data.source_url;
      setFileList(newFileList);
    }
  };
  const onChangeCover = (e) => {
    props.values.cover_image = e.target.value;
  };

  const handlePreview = async (file) => {
    if (file.originFileObj) {
      setLoading(true);
      setPreviewImage(file);
      setLoading(false);
      setPreviewVisible(true);
      setPreviewTitle(
        file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
      );
    } else {
      if (isS3Url(file.url)) {
        message.error(`Photos cannot be edited after saving the report!`);
      } else {
        createFile(file);
      }
    }
  };
  async function createFile(file) {
    let response = await fetch(file.url);
    let data = await response.blob();
    file.originFileObj = data;
    setPreviewImage(file);
    setLoading(false);
    setPreviewVisible(true);
  }
  const isS3Url = (url) => {
    const s3BucketDomains = [
      's3.amazonaws.com',
      'https://fddatainsp.s3.us-east-2.amazonaws.com',
      'https://fdbucketupload.s3-accelerate.amazonaws.com/'
    ]; // Add more S3 bucket domains if needed

    return s3BucketDomains.some((domain) => url.includes(domain));
  };


  const showModal = () => {
    fetchCompanycam();
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancelModal = () => {
    setIsModalOpen(false);
  };
  const showTokenModal = () => {
    setIsModalToken(true);
  };
  const handleOkToken = () => {
    setIsModalToken(false);
  };
  const handleCancelToken = () => {
    setIsModalToken(false);
  };
  const showHelpModal = () => {
    setIsModalHelp(true);
  };
  const handleOkHelp = () => {
    setIsModalHelp(false);
  };
  const handleCancelHelp = () => {
    setIsModalHelp(false);
  };

  const fetchProjects = (pageNum) => {
    if (!hasMore || loading) return;

    setLoading(true);

    const params = { page: pageNum };

    dispatch(getProjects(params))
      .then((response) => {
        // Assuming your API returns both data and metadata about pagination
        const newProjects = response.data;

        // If no new projects returned, we've reached the end
        if (newProjects.length === 0) {
          setHasMore(false);
        } else {
          // Append new projects to existing ones
          setProjects(prev => [...prev, ...newProjects]);
          setPage(pageNum);
        }
      })
      .catch((error) => {
        message.error('Failed to fetch projects');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleScroll = () => {
    if (!sidebarRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = sidebarRef.current;

    // Check if we're near the bottom (within 50px)
    if (scrollHeight - scrollTop - clientHeight < 50 && !loading && hasMore) {
      fetchProjects(page + 1);
    }
  };

  useEffect(() => {
    const fd_backend_token = localStorage.getItem('fd_backend_token');
    if (fd_backend_token) {
      setHideFdcamButton(false);
      fetchProjects(1);
    }
  }, []);
  const showFDCamModal = () => {
    setIsFDCamModalOpen(true);
  };
  const handleFDCamOk = () => {
    setIsFDCamModalOpen(false);

  };
  const handleCancelFDCamModal = () => {
    setIsFDCamModalOpen(false);
  };
  const handleFdCamCheck = (value, fdCamProject) => {
    if (value?.target?.checked === true) {
      setSelectImagesFDCam(true);
      setFDCamImages(fdCamProject?.inspection_images?.map((item) => ({
        id: item?.id,
        media_id: item?.id,
        uid: `${item.id}_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
        name: `image_${item?.id}_${Date.now()}`,
        url: item?.image_url,
        description: item?.description
      })));
    } else {
      setSelectImagesFDCam(false);
      setFDCamImages([]);
    }
  };

  const handleImageCheck = (imageId) => {
    const newSelectedImages = fDCamImages.some(image => image.id === imageId)
      ? fDCamImages.filter(image => image.id !== imageId)
      : [...fDCamImages, {
        id: imageId,
        url: fdCamSelectedProject.inspection_images.find(img => img.id === imageId)?.image_url,
        description: fdCamSelectedProject.inspection_images.find(img => img.id === imageId)?.description
      }];

    setFDCamImages(newSelectedImages);

    // Update the "Select All" checkbox state
    if (newSelectedImages.length === fdCamSelectedProject?.inspection_images?.length) {
      setSelectImagesFDCam(true);
    } else {
      setSelectImagesFDCam(false);
    }
  };

  const handleSelectFdCamSelect = (projectitem) => {
    setFdCamLoader(true)
    setfdCamSelectedProjects(projectitem);
    setFDCamImages([]);
    setSelectImagesFDCam(false)
    setFdCamLoader(false)
  }

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <Form.Item
          name="dragger"
          valuePropName={fileList}
          getValueFromEvent={normFile}
          noStyle
        >
          <Upload
            name="files"
            multiple={true}
            accept="image/*"
            beforeUpload={beforeUpload}
            customRequest={uploadImage}
            listType="picture-card"
            fileList={fileList}
            onChange={onChange}
            onPreview={handlePreview}
            progress={progress}
            itemRender={(originNode, file, currFileList) => (
              <>
                <div className="uploaded-img-wrapper image_attachment_uploader">
                  <div className="img-section">
                    <Fancybox>
                      <a
                        data-fancybox="gallery"
                        href={file.url}
                        className="zIndex"
                      >
                        <EyeOutlined />
                      </a>
                    </Fancybox>
                    <DragableUploadListItem
                      originNode={originNode}
                      file={file}
                      fileList={currFileList}
                      moveRow={moveRow}
                      onChange={takefileList(currFileList)}
                    />
                    <div className="upload-img-number">
                      <span className="img-number">
                        {currFileList.indexOf(file) + 1}
                      </span>
                    </div>
                  </div>

                  <div className="img-text-wrapper">
                    <div className="input-header">
                      <Title level={5}>Description</Title>
                    </div>
                    <Form.Item
                      name={[
                        'Allimagedescription',
                        file.uid.toString(),
                        'caption',
                      ]}
                      onChange={handleChange(file.uid.toString())}
                      initialValue={
                        props.values.Allimagedescription?.[file.uid.toString()]
                          ?.caption
                          ? props.values?.Allimagedescription[
                            file?.uid.toString()
                          ].caption
                          : file.desc
                      }
                    >
                      <Input.TextArea className="input-img" />
                    </Form.Item>
                  </div>
                  <div className="img-footer">
                    <Form.Item name="cover_image">
                      <Radio.Group
                        defaultValue={
                          parseInt(file.cover) === file.media_id ? file.media_id : ''
                        }
                        onChange={onChangeCover}
                      >
                        <Radio
                          name="cover_image"
                          value={file?.media_id ? file?.media_id : file?.uid}
                        >
                          <p>Please Select Cover Photo</p>
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
              </>
            )}
          >
            <p className="file-image-drag-btn">Drop or Upload Photos Only Here (Videos Not Supported)</p>
          </Upload>
        </Form.Item>
      </DndProvider>
      <div className="CompanySection">
        <Row gutter={16} className="mb-3">
          <Col xs={24} md={24} className="text-center companyCamButton">
            <Button className="btnPrimary2" onClick={showModal}>
              <img
                src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                alt="company-logos"
              ></img>
            </Button>
          </Col>
        </Row>
      </div>
      {!hideFdcamButton && (
        <div className="fdAppbuttonSection mx-3">
          <Row gutter={16} className="mb-3">
            <Col xs={24} md={24} className="text-center fdAppCamButton">
              <Button className="btnPrimary2" onClick={showFDCamModal}>
                <img
                  className="fd-cam-image"
                  src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21062258/no-image-165fc0a7fd134865fc0a7fd1f97.jpg"
                  alt="fd-logos"
                />
                <b>Photo App</b>
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <Modal
        className="editor_rotate_Model"
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <ImageEditor
          image={previewImage}
          fileList={fileList}
          setFileList={setFileList}
          setPreviewVisible={setPreviewVisible}
          loading={loading}
          setLoadings={props.setLoadings}
        />
      </Modal>

      <Modal
        centered
        open={isModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel"
        width={1000}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancelModal}
        closable={false}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title">
                <Title level={4}>CompanyCam's Projects</Title>
              </div>
              <div className="modal-header-logo model-logo-center">
                <div className="modal-header-edit-logo">
                  <Tooltip title="Edit Your Companycam Token" arrow>
                    <Button className="btnPrimary2" onClick={showTokenModal}>
                      <img
                        src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/04/24023652/cog-regular-36.png"
                        alt="edit-logo"
                      ></img>
                    </Button>
                  </Tooltip>
                </div>
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Form
          name="companyCamform"
          layout="vertical"
          autoComplete="off"
          form={form1}
          onFinish={saveCompanyCamMedia}
        >
          <div>
            <Row className="my-2 footerRow" gutter={6}>
              <Col className="col-4">
                <Row className="my-2 footerRow" gutter={6}>
                  {Object.keys(companyCam).map((keyName, index) => (
                    <Col className="col-12 h-100 mb-2" key={index}>
                      {/* className="companyCam-project-box" */}
                      <a
                        href="#:"
                        className={
                          active === index
                            ? ' companyCam-project-box activeCard'
                            : 'companyCam-project-box'
                        }
                        onClick={() =>
                          fetchCompanycamProImg(companyCam[keyName]?.id, index)
                        }
                      >
                        <div className="logoDiv companyCam-project-outer">
                          <div className="companyCam-pro-thumb">
                            <Image
                              src={companyCam[keyName]?.feature_image[0]?.uri}
                              className="img-fluid"
                              alt="logos"
                              preview={false}
                            />
                          </div>
                          <div className="company-project-details px-2">
                            <h6>
                              <span>
                                <b>Project </b>:-
                              </span>
                              <span className="company-project-title">
                                {companyCam[keyName]?.name}
                              </span>
                            </h6>
                            <p>
                              <span>
                                <b>Address </b>:-
                              </span>
                              <span>
                                {
                                  companyCam[keyName]?.address[
                                  'street_address_1'
                                  ]
                                }
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['city']}
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['state']}
                              </span>
                              ,{' '}
                              <span>
                                {companyCam[keyName]?.address['postal_code']}
                              </span>
                            </p>
                            <p>
                              <span>
                                <b>Photos </b>:-
                              </span>
                              {companyCam[keyName]?.photo_count}
                            </p>
                          </div>
                        </div>
                      </a>
                    </Col>
                  ))}
                </Row>
              </Col>
              <Col className="col-8">
                {uploading === true ? (
                  <CustomSpinner progress={cCprogress} />
                ) : (
                  ''
                )}

                {camLoader ? (
                  <div className="camLoader">
                    <Spin size="large" />
                    {/* <CustomSpinner /> */}
                  </div>
                ) : (
                  <div className="companyCam-single-project-details">
                    <h2>{companyCamHeading}</h2>
                    <div
                      className="form-check"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '25px',
                      }}
                    >
                      <h2>
                        <Checkbox
                          onChange={handleCheckAllChange}
                          checked={
                            selectedImages.length === companyCamProImg.length
                          }
                        >
                          Select all
                        </Checkbox>
                      </h2>
                    </div>
                    <p>
                      <span>{CompanycamAddressStreet}</span>
                      {CompanycamAddressStreet && ', '}
                      <span>{CompanycamAddressCity}</span>
                      {CompanycamAddressCity && ', '}
                      <span>{CompanycamAddressState}</span>
                      {CompanycamAddressState && ', '}
                      <span>{CompanycamAddressPostal}</span>
                    </p>
                  </div>
                )}
                <Row className="my-2 footerRow" gutter={6}>
                  {companyCamProImg.length > 0 ? (
                    <ul className="Companyproject_images-outer">
                      {Object.keys(companyCamProImg).map((keyName, index) => (
                        <>
                          <li className="Companyproject_images-box">
                            <input
                              type="checkbox"
                              className="checkboxstyle"
                              id={'checkbox' + index}
                              key={companyCamProImg[keyName]?.id}
                              value={companyCamProImg[keyName]?.uris[3]?.uri}
                              onChange={hadleChangeCompanycam}
                              checked={selectedImages?.includes(
                                companyCamProImg[keyName]?.uris[3]?.uri
                              )}
                            />
                            <label htmlFor={'checkbox' + index}>
                              <div className="logoDiv companyCam">
                                <Image
                                  src={companyCamProImg[keyName]?.uris[3]?.uri}
                                  className="img-fluid"
                                  alt="logos"
                                  preview={false}
                                />
                              </div>
                            </label>
                          </li>
                        </>
                      ))}
                    </ul>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <SmileOutlined style={{ fontSize: 20 }} />
                      <p>Please select a project from sidebar!</p>
                    </div>
                  )}
                </Row>
              </Col>
            </Row>
          </div>

          <div className="modelButtons">
            <Button className="btnPrimary" onClick={handleCancelModal}>
              Cancel
            </Button>
            <Button
              className="btnPrimary"
              htmlType="submit"
              disabled={uploading}
            >
              Upload and Exit
            </Button>
          </div>
        </Form>
      </Modal>

      {/* ADD TOKEN MODEL  */}
      <Modal
        // title={"CompanyCam's Projects" }
        centered
        open={isModalToken}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel"
        width={1000}
        footer={null}
        onOk={handleOkToken}
        onCancel={handleCancelToken}
        closable={true}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title">
                <Title level={4}>Add CompanyCam Token</Title>
              </div>
              <div className="modal-header-logo">
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Form
          name="companyCamforms"
          layout="vertical"
          autoComplete="off"
          form={form2}
          onFinish={setCompanycamToken}
        >
          <div className="companyToken-inst">
            <Row className="my-2 footerRow" gutter={6}>
              <Col xs={24} md={24}>
                <h2 className="companyToken-title">
                  Please input your Companycam token
                </h2>
                <div className="companyToken-form">
                  <Row gutter={16} className="companyToken-row">
                    <Col xs={24} md={18} xl={18}>
                      <Item
                        name="companycam_token"
                        placeholder="Please input your Companycam token"
                        rules={[
                          {
                            required: true,
                            message: 'Please input your Companycam token',
                          },
                          {
                            min: 40,
                            message: 'Please enter a valid token',
                          },
                          {
                            max: 49,
                            message: 'Please enter a valid token',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Please input your Companycam token"
                          className="formControl"
                          defaultValue={companyCamToken}
                        />
                      </Item>
                      {companyCamToken && companyCam.length === 0 ? (
                        <Result
                          status="error"
                          title="Your Token might be expired or invalid."
                        />
                      ) : (
                        ''
                      )}
                    </Col>
                    <Col xs={24} md={6} xl={6}>
                      <Form.Item>
                        <Button
                          className="btnPrimary"
                          type="primary"
                          htmlType="submit"
                        >
                          Submit
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
        <div className="helpModel-heading">
          <h3>How to create company cam access token?</h3>
          <Button className="btnPrimary" onClick={showHelpModal}>
            Help
          </Button>
        </div>
      </Modal>

      {/* HELP TOKEN MODEL  */}
      <Modal
        // title={"CompanyCam's Projects" }
        centered
        open={isModalHelp}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel HelpTokenModel"
        width={1000}
        footer={null}
        onOk={handleOkHelp}
        onCancel={handleCancelHelp}
        closable={false}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title"></div>
              <div className="modal-header-logo">
                <Image
                  src="https://d33v4339jhl8k0.cloudfront.net/docs/assets/5e94d6a504286364bc984894/images/630d36a64cde766bbe140183/Full-Color-Over-White.png"
                  className="img-fluid"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>

        <div>
          <Row className="my-2 footerRow" gutter={6}>
            <Col xs={24} md={24}>
              <section className="timeline customAntImageWrapper">
                <h1>How To Generate CompanyCam Access Token?</h1>
                <Image
                  src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2023/05/19041149/ezgif-1-346a85312d.gif"
                  className="img-fluid"
                  alt="images"
                  preview={false}
                />
              </section>
            </Col>
          </Row>
        </div>
        <div className="modelButtons">
          <Button
            className="btnPrimary"
            htmlType="submit"
            onClick={handleButtonClick}
          >
            Generate Token
          </Button>
          <Button
            className="btnPrimary"
            htmlType="submit"
            onClick={handleCancelHelp}
          >
            Ok
          </Button>
        </div>
      </Modal>
      {/* FdCam Model */}
      <Modal
        centered
        open={isFDCamModalOpen}
        className="inspectionCategoryModal industryModal customIndustryModal companyCamModel"
        width={1000}
        footer={null}
        onOk={handleFDCamOk}
        onCancel={handleCancelFDCamModal}
        closable={false}
      >
        <Row>
          <Col xs={24} md={24}>
            <div className="modal-header">
              <div className="modal-header-title">
                <Title level={4}>FDCam's Projects</Title>
              </div>
              <div className="modal-header-logo model-logo-center">
                <Image
                  src="https://fddatainsp.s3.us-east-2.amazonaws.com/wp-content/uploads/2021/08/21062258/no-image-165fc0a7fd134865fc0a7fd1f97.jpg"
                  className="img-fluid fd-cam-model-logo"
                  alt="logos"
                  preview={false}
                />
              </div>
            </div>
          </Col>
        </Row>
        <Form name="fdCamForm" layout="vertical" autoComplete="off" form={fdForm} onFinish={saveFDCamMedia}>
          <div>
            <Row className="my-2 footerRow fd-cam-project-model" gutter={6}>
              <Col className="col-4"
                ref={sidebarRef}
                onScroll={handleScroll}
                style={{
                  maxHeight: '100vh',
                  overflowY: 'auto',
                  paddingRight: '10px'
                }}
              >
                {projects.length > 0 ? (
                  <>
                  {projects.map((projectitem) => (
                    <div className="project-box-key" onClick={() => handleSelectFdCamSelect(projectitem)} key={projectitem.id}>
                      <div className={projectitem?.id === fdCamSelectedProject?.id ? "active_fd_cam_project fd-camproject-box" : "fd-camproject-box"}>
                        <div className="row">
                          <div className="col-md-5 col-sm-12 fd-camproject-box-image">
                            <div className="project-card-image">
                             
                              {projectitem?.inspection_images?.length > 0 ? (
                                <img className="pro-folder-img" alt="folder-image" src={projectitem?.inspection_images[0]?.image_url} />
                              ) : (
                                <img className="pro-folder-img-icon" alt="folder-image" src={FolderIMage} />
                              )}
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-12 fd-camproject-box-content">
                            <h4 className="card-meta-title">{projectitem?.name}</h4>
                            <p className="project-location">
                              <b>Address</b> :- {projectitem?.address?.trim() ? projectitem.address : projectitem?.street}, {projectitem?.city}, {projectitem?.state}, {projectitem?.postal_code}
                            </p>
                            <div className="project-inner-item">
                              <p className="pro-item-count"><b>Photos</b> :- {projectitem?.inspection_images?.length}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                   {loading ? (<div className='fd-cam-project-loading-section text-center'><span className='text-center'>Please wait, the project is loading...</span><br/><Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} /></div>) : (<></>)}
                  </>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <SmileOutlined style={{ fontSize: 20 }} />
                    <p>No projects available!</p>
                  </div>
                )}
              </Col>
              <Col className="col-8 px-3 padding-col-right-0">
                <div className="fdCam-single-project-images">
                  {fdCamLoader ? (
                    <Skeleton active paragraph={{ rows: 4 }} />
                  ) : fdCamSelectedProject ? (
                    <>
                      <p className="fDCam-single-project-detail-title">{fdCamSelectedProject?.name}</p>
                      <p className="fDCam-single-project-detail-address">
                        {fdCamSelectedProject?.address?.trim() ? fdCamSelectedProject.address : fdCamSelectedProject?.street}, {fdCamSelectedProject?.city}, {fdCamSelectedProject?.state}, {fdCamSelectedProject?.postal_code}
                      </p>
                      <div className="text-end my-2">
                        <Checkbox checked={selectImagesFDCam} onChange={(e) => handleFdCamCheck(e, fdCamSelectedProject)}><span className="select-all-text">Select All</span></Checkbox>
                      </div>
                      <Row>
                        {fdCamSelectedProject?.inspection_images?.map((project) => (
                          <Col className="px-2 mobile-full-width" xl={6} md={8} sm={12} key={project?.id}>
                            <div className="fd-cam-single-checkbox-image">
                              <Checkbox
                                checked={fDCamImages.some(image => image.id === project?.id)}
                                onChange={() => handleImageCheck(project?.id)}
                              >
                                <Image
                                  preview={false} src={project?.image_url}
                                  placeholder={
                                    <Image
                                      preview={false}
                                      src={project?.thumb_url}
                                    />
                                  }
                                />
                              </Checkbox>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <SmileOutlined style={{ fontSize: 20 }} />
                      <p>Please select a project from the sidebar!</p>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="modelButtons">
            <Button className="btnPrimary" onClick={handleCancelFDCamModal}>Cancel</Button>
            <Button className="btnPrimary" htmlType="submit" disabled={uploading}>Upload and Exit</Button>
          </div>
        </Form>
      </Modal>

    </>
  );
};

export default App;
