import React, { useEffect, useState } from 'react';
import { Button, message, Input, Skeleton, Row, Col, Pagination } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { getProjects } from '../../services/projectServices';
import { useDispatch } from "react-redux";
import FolderIMage from "../../assets/images/blue-directory-icon.png"
import moment from 'moment';
import ButtonBar from "../../components/ButtonNavigation"
const { Search } = Input;
const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [originalProjectData, setOriginalProjectData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 15,
    total: 0
  });
  const navigate = useNavigate();
  var dispatch = useDispatch();
  const size = 'large';
  const fetchProjects = (page = 1, search = '') => {
    setLoading(true);
    const params = {
      page,
      ...(search && { search })
    };

    dispatch(getProjects(params))
      .then((response) => {
        // Correctly handle the paginated response
        const { data, current_page, per_page, total } = response;

        setProjects(data);
        if (!search) {
          setOriginalProjectData(data);
        }

        setPagination({
          current: current_page,
          pageSize: per_page,
          total: total
        });
        setLoading(false);
      })
      .catch((error) => {
        message.error('Failed to fetch projects');
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchProjects(page, searchValue);
  };
  useEffect(() => {
    fetchProjects(1);
  }, []);

  const onSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);

    if (value !== '') {
      if (value.length >= 2) { // Optional: Only search if at least 2 characters
        fetchProjects(1, value);
      } else {
        const filteredData = originalProjectData?.filter((filteritem) => (
          filteritem?.name?.toLowerCase()?.includes(value) ||
          filteritem?.address?.toLowerCase()?.includes(value) ||
          filteritem?.description?.toLowerCase()?.includes(value)
        ));
        setProjects(filteredData);
      }
    } else {
      fetchProjects(1); // Reset to first page when search is cleared
    }
  };

  return (
    <div className="p-6">
      <div className='container-xxl px-xl-5 px-lg-3'>
        <div className='button-navigations mt-5'>
          <ButtonBar />
        </div>

        <div className="row mb-5 mt-5">
          <div className='col-md-5'>
            <h1 className="text-2xl project-list-main-title">Projects</h1>
          </div>
          <div className='col-md-7'>
            <div className='project-create-search'>
              <Search placeholder="Find a project..." onChange={onSearch} style={{ width: 250 }} />
              <Button type="primary" onClick={() => navigate('/projects/create')}>
                Create Project <PlusCircleOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="container-xxl  px-xl-5 px-lg-3">
          <div className="Skeleton_cover px-0 project-list-page-Skeleton">
            {
              (() => {
                const rows = [];
                for (let i = 0; i < 4; i++) {
                  rows.push(
                    <Row gutter={16} className="d-flex align-items-center justify-content-md-start mb-4" key={i} style={{ borderBottom: "1px solid #f0f0f0", paddingBottom: "10px" }} >
                      <Col xs={8} md={3} xl={3} className="sklpad">
                        <Skeleton.Image className='prolist-skeleton-large-img' active size="large" style={{ height: "120px", width: "120px", borderRadius: "8px" }} />
                      </Col>
                      <Col xs={16} md={10} xl={10} className="sklpad mb-sm-2">
                        <Skeleton.Input className="mb-1" block={true} active size="size" style={{ height: "22px" }} />
                        <Skeleton.Input className="mb-1" block={true} active size="large" style={{ height: "26px" }} />
                        <Skeleton.Input className="mb-1" block={true} active size="large" style={{ height: "18px" }} />
                      </Col>
                      <Col xs={24} md={3} xl={3} className="sklpad mobile-border-top">
                        <Skeleton.Input className="mb-1" block={true} active size="small" style={{ height: "18px" }} />
                        <Skeleton.Input className="mb-1" block={true} active size="small" style={{ height: "24px" }} />
                      </Col>
                      <Col xs={24} md={8} xl={8} className="sklpad d-flex justify-content-between skeleton-multiimage">
                        <Skeleton.Image active size="large" style={{ height: "100px", width: "100px", borderRadius: "8px" }} />
                        <Skeleton.Image active size="large" style={{ height: "100px", width: "100px", borderRadius: "8px" }} />
                        <Skeleton.Image active size="large" style={{ height: "100px", width: "100px", borderRadius: "8px" }} />
                        <Skeleton.Image active size="large" style={{ height: "100px", width: "100px", borderRadius: "8px" }} />
                      </Col>
                    </Row>
                  );
                }
                return rows;
              })()
            }





          </div>
        </div>
      ) : (
        <>
          <div className='project-outer-section container-xxl mb-5  px-xl-5 px-lg-3'>
            <div className='row'>
              {projects && projects?.length > 0 ? (
                <>
                  {projects.map((projectitem) => (
                    <div className='col-md-12' key={projectitem.id}>
                      <div className='single-project-list' onClick={() => navigate(`/project-detail/?project_id=${projectitem?.id}`)}>
                        <div className='row align-items-center'>
                          <div className='col-md-6'>
                            <div className='row align-items-center mobile-border-bottom'>
                              <div className='col-md-3 col-sm-4 col-4'>
                                <div className='project-card-image'>
                                  <div className='project-image'>
                                    {projectitem?.inspection_images?.length > 0 ? (
                                      <img
                                        className='pro-folder-img'
                                        alt='folder-image'
                                        src={projectitem?.inspection_images[0]?.image_url}
                                      />
                                    ) : (
                                      <img
                                        className='pro-folder-img-icon'
                                        alt='folder-image'
                                        src={FolderIMage}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-9 col-sm-8 col-8'>
                                <h4 className='card-meta-title'>{projectitem?.name}</h4>
                                <p className='project-location'>
                                  {projectitem?.address?.trim() ? projectitem.address : projectitem?.street}, {projectitem?.city}, {projectitem?.state}, {projectitem?.postal_code}
                                </p>
                                <p className='project-description'>{projectitem?.description}</p>
                                <p className='project-date'>
                                  Created At{' '}
                                  {moment(projectitem?.created_at).format('MMM D, h:mm A')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='col-lg-1 col-md-2'>
                            <div className='project-inner-item'>
                              <p>Photos</p>
                              <p className='pro-item-count'>{projectitem?.inspection_images?.length}</p>
                            </div>
                          </div>
                          <div className='col-lg-5 col-md-4'>
                            <div className='row d-flex'>
                              {projectitem?.inspection_images?.length > 0 && (
                                <>
                                  {projectitem.inspection_images.slice(0, 4).map((item, index) => (
                                    <div className='col-lg-3 col-md-6 col-sm-3 col-3 px-1 project-multiimage' key={index}>
                                      <img
                                        className='project-list-images'
                                        width={120}
                                        height={120}
                                        src={item?.image_url}
                                        alt={`Inspection ${index + 1}`}
                                      />
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-12 mt-4">
                    <div className="d-flex justify-content-end">
                      <Pagination
                        current={pagination.current}
                        pageSize={pagination.pageSize}
                        total={pagination.total}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        showQuickJumper={false}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <p className='project-not-found'>Project Not Found</p>
              )}
            </div>
          </div>
        </>
      )
      }


    </div >
  );
};
export default ProjectList;
