import React, { useState, useCallback } from 'react';
import { Form, Input, Button, Select, message, Card, Space, Row, Col } from 'antd';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';

const { Option } = Select;
const { TextArea } = Input;

const mapContainerStyle = {
  width: '100%',
  height: '300px',
};

const DEFAULT_CENTER = {
  lat: 37.7749,
  lng: -122.4194,
};

const libraries = ['places', 'geocoding'];

const ProjectForm = ({ initialValues, onSubmit, buttonText, loading = false }) => {
  const [form] = Form.useForm();

  const initialLat = initialValues?.latitude ? parseFloat(initialValues.latitude) : DEFAULT_CENTER.lat;
  const initialLng = initialValues?.longitude ? parseFloat(initialValues.longitude) : DEFAULT_CENTER.lng;

  const [location, setLocation] = useState({
    lat: initialLat,
    lng: initialLng,
  });

  // Load Google Maps with additional libraries
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // Function to update form fields with geocoding results
  const updateAddressFields = async (lat, lng) => {
    try {
      const geocoder = new window.google.maps.Geocoder();
      const results = await geocoder.geocode({
        location: { lat, lng }
      });

      if (results.results[0]) {
        const addressComponents = results.results[0].address_components;
        let streetNumber = '', route = '', city = '', state = '', country = '', postalCode = '';

        // Extract address components
        addressComponents.forEach(component => {
          const types = component.types;
          if (types.includes('street_number')) {
            streetNumber = component.long_name;
          } else if (types.includes('route')) {
            route = component.long_name;
          } else if (types.includes('locality')) {
            city = component.long_name;
          } else if (types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (types.includes('country')) {
            country = component.long_name;
          } else if (types.includes('postal_code')) {
            postalCode = component.long_name;
          }
        });

        // Update form fields
        form.setFieldsValue({
          address: `${streetNumber} ${route}`.trim(),
          city,
          state,
          country,
          postal_code: postalCode,
          latitude: lat.toString(),
          longitude: lng.toString(),
        });
      }
    } catch (error) {
      message.error('Error fetching address details');
    }
  };

  // Handle marker drag end
  const handleMarkerDragEnd = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLocation({ lat, lng });
    updateAddressFields(lat, lng);
  }, [form]);

  // Handle map click
  const handleMapClick = useCallback((event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setLocation({ lat, lng });
    updateAddressFields(lat, lng);
  }, [form]);

  const handleSubmit = async (values) => {
    try {
      await onSubmit({
        ...values,
        latitude: location.lat,
        longitude: location.lng,
      });

    } catch (error) {
      message.error('Failed to update project');
    }
  };

  if (loadError) {
    return (
      <div style={{ color: '#ff4d4f', padding: '16px' }}>
        Error loading Google Maps. Please check your API key and try again.
      </div>
    );
  }

  if (!isLoaded) {
    return <div style={{ padding: '16px' }}>Loading Map...</div>;
  }

  return (
    <Card className='porject-edit-form'>
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={{
          name: initialValues?.name || '',
          description: initialValues?.description || '',
          address: initialValues?.street || '',
          city: initialValues?.city || '',
          state: initialValues?.state || '',
          country: initialValues?.country || '',
          postal_code: initialValues?.postal_code || '',
          status: initialValues?.status || 'pending',
          latitude: initialValues?.latitude?.toString() || '',
          longitude: initialValues?.longitude?.toString() || '',
        }}
      >
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {/* Project Name */}
          <Row gutter={16}>
            <Col xs={24} md={8} xl={8}>
              <Form.Item
                name="name"
                label="Project Name"
                rules={[{ required: true, message: 'Please enter project name' }]}
              >
                <Input className="formControl" placeholder="Enter project name" />
              </Form.Item>
            </Col>
          {/* Address Fields */}
          <Col xs={24} md={8} xl={8}>
          <Form.Item
            name="address"
            label="Street Address"
            rules={[{ required: false, message: 'Please enter address' }]}
          >
            <Input  className="formControl" placeholder="Enter street address" />
          </Form.Item>
          </Col>
          
          <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="city"
              label="City"
              style={{ width: '100%' }}
              rules={[{ required: false, message: 'Please enter city' }]}
            >
              <Input className="formControl" placeholder="Enter city" />
            </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="state"
              label="State"
              style={{ width: '100%' }}
              rules={[{ required: false, message: 'Please enter state' }]}
            >
              <Input className="formControl" placeholder="Enter state" />
            </Form.Item>
            </Col>
          

            <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="country"
              label="Country"
              style={{ width: '100%' }}
              rules={[{ required: false, message: 'Please enter country' }]}
            >
              <Input className="formControl" placeholder="Enter country" />
            </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="postal_code"
              label="Postal Code"
              style={{ width: '100%' }}
              rules={[{ required: false, message: 'Please enter postal code' }]}
            >
              <Input className="formControl" placeholder="Enter postal code" />
            </Form.Item>
            </Col>

          {/* Coordinates */}
          <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="latitude"
              label="Latitude"
              style={{ width: '100%' }}
            >
              <Input className="formControl" disabled />
            </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
            <Form.Item
              name="longitude"
              label="Longitude"
              style={{ width: '100%' }}
            >
              <Input className="formControl" disabled />
            </Form.Item>
            </Col>
            <Col xs={24} md={8} xl={8}>
          {/* Status */}
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: false, message: 'Please select a status' }]}
          >
            <div className="formGroup">
            <Select className="formGroup" placeholder="Select status">
              <Option value="pending">Pending</Option>
              <Option value="in-progress">In Progress</Option>
              <Option value="completed">Completed</Option>
            </Select>
            </div>
          </Form.Item>
          </Col>
          </Row>
          {/* Description */}
          <Row gutter={16}>
            <Col xs={24} md={24} xl={24}>
              <Form.Item
                name="description"
                label="Description"
                rules={[{ required: false, message: 'Please enter description' }]}
              >
                <TextArea className="formControl" rows={4} placeholder="Enter project description" />
              </Form.Item>
            </Col>
          </Row>
          {/* Google Map */}
          <Row gutter={16}>
            <Col xs={24} md={24} xl={24}>
              <Form.Item label="Pick Location (Click on map or drag marker)">
                <div style={{ border: '1px solid #d9d9d9', borderRadius: '8px', overflow: 'hidden' }}>
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={10}
                    center={location}
                    onClick={handleMapClick}
                    options={{
                      streetViewControl: false,
                      mapTypeControl: false,
                    }}
                  >
                    <Marker
                      position={location}
                      draggable={true}
                      onDragEnd={handleMarkerDragEnd}
                    />
                  </GoogleMap>
                </div>
              </Form.Item>
            </Col>
          </Row>
          
          {/* Submit Button */}
          <Form.Item className='text-center'>
            <div className='project-edit-submit'>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {buttonText}
            </Button>
            </div>
          </Form.Item>
        </Space>
      </Form>
    </Card>
  );
};

export default ProjectForm;