import axios from 'axios';
import { LARAVEL_BACKEND_API_URL } from './Helper'; // Use named import
// Define API configurations
const API_CONFIGS = {
  'https://www.floordetective.com/wp-json': {
    tokenKey: 'jwt_token',
  },
  'https://api.companycam.com': {
    tokenKey: 'companycam_token',
  },
  [LARAVEL_BACKEND_API_URL]: { // Using dynamic key
    tokenKey: 'fd_backend_token',
  },
};

// Helper function to get token based on URL
const getTokenForUrl = (url) => {
  const matchingConfig = Object.entries(API_CONFIGS).find(([baseUrl]) =>
    url.includes(baseUrl)
  );

  if (matchingConfig) {
    const [, config] = matchingConfig;
    const token = localStorage.getItem(config.tokenKey);
    return `Bearer ${token}`;
  }

  // Default token or handling if no matching URL is found
  return null;
};

// Response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// Request interceptor
axios.interceptors.request.use((config) => {
  const token = getTokenForUrl(config.url);

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axios;