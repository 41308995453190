import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { Link } from "react-router-dom";
import { Logo } from "../../constant/images";
import DrawerMenu from './drawer';
import { useSelector } from "react-redux";

function NavBar() {
  const userDetails = useSelector((state) => state?.User?.userDetails);
  const [visible, setVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState('');

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };


  useEffect(() => {
    setCurrentUser(userDetails);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);


  return (
    <div className="navBar">
      <nav className="navbar navbar-expand-lg">
        <div className="container-xxl">
          <Link className="navbar-brand" to={"/"}>
            <img src={Logo} className="img-fluid" alt="logo" />
          </Link>
          <button className="navbar-toggler" type="button" onClick={showDrawer}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <Drawer
            placement="left"
            onClose={onClose}
            visible={visible}
            className="mobSider"
          >
            <DrawerMenu />
          </Drawer>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" href='https://www.floordetective.com/' target="_blank" rel="noopener noreferrer nofollow" >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://www.floordetective.com/subscribe/' target="_blank" rel="noopener noreferrer nofollow" >
                  Memberships
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://www.floordetective.com/' target="_blank" rel="noopener noreferrer nofollow" >
                  Defects
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://www.floordetective.com/locator/' target="_blank" rel="noopener noreferrer nofollow" >
                  Find An Inspector
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href='https://www.floordetective.com/about-us/' target="_blank" rel="noopener noreferrer nofollow" >
                  About Us
                </a>
              </li>
              {currentUser?.user_role === 'um_dealer' ? 
                <li className="nav-item">
                  <a className="nav-link" href='/dealer-panel' rel="noopener noreferrer nofollow" >
                   Dealer Panel
                  </a>
                </li> : 
                <li className="nav-item">
                  <a className="nav-link" href='https://www.floordetective.com/membership-account/' target="_blank" rel="noopener noreferrer nofollow" >
                    Account
                  </a>
                </li>
              }
              
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
