import React, { useState } from 'react';
import FilerobotImageEditor, { TABS, TOOLS } from 'react-filerobot-image-editor';
import { updateProjectImage } from '../../services/projectServices';
import { message } from 'antd';
import { useDispatch } from "react-redux";


const ImageEditorModal = ({
  imageUrl,
  project_id,
  image_id,
  setEditorVisible,
  setImageId,
  setSelectedImage,
  fetchProject,
}) => {
  const [isImgEditorShown, setIsImgEditorShown] = useState(true);
  const dispatch = useDispatch();

  function dataURLtoBlob(dataURL) {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }


  const handleImageSave = async (editedImage) => {
    try {
      setIsImgEditorShown(false)
      const formData = new FormData();
      const blob = dataURLtoBlob(editedImage.imageBase64);
      const file = new File([blob], editedImage.fullName, { type: editedImage.mimeType });
      formData.append('image', file);
      formData.append('project_id', project_id);


      dispatch(updateProjectImage(project_id, image_id, formData))
        .then(() => {
          fetchProject()
          message.success('Image has been updated successfully!');
        })
        .catch((error) => {
          message.error('Failed to create project');
        })

      setIsImgEditorShown(false);
      setEditorVisible(false)
      setImageId(null)
      setSelectedImage(null)

    } catch (error) {
      message.error('Failed to upload image');
    }
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
    setEditorVisible(false)
    setImageId(null)
    setSelectedImage(null)
  };


  return (

    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full h-full max-w-6xl max-h-[90vh] bg-white rounded-lg overflow-hidden">
        {isImgEditorShown && (
          <FilerobotImageEditor
            source={imageUrl}
            
            onBeforeSave={() => false}
            onSave={(editedImageObject) => {
              handleImageSave(editedImageObject);
            }}
            onClose={closeImgEditor}
            annotationsCommon={{
              fill: 'transparent',
              stroke: '#FAFA33',
              strokeWidth: 2,
            }}
            Text={{
              text: 'Text...',
              fontFamily: 'Poppins',
              fontSize: 40,
              fill: '#FAFA33',
              letterSpacing: 1,
              bold: false,
              strokeWidth: 0,
              strokeColor: '#FFFFFF',
            }}
            // Text={{ text: 'Text...'}}
            Rotate={{ angle: 90, componentType: 'slider' }}
            Crop={{
              presetsItems: [
                {
                  titleKey: 'classicTv',
                  descriptionKey: '4:3',
                  ratio: 4 / 3,
                },
                {
                  titleKey: 'cinemascope',
                  descriptionKey: '21:9',
                  ratio: 21 / 9,
                },
              ],
              presetsFolders: [
                {
                  titleKey: 'socialMedia',
                  groups: [
                    {
                      titleKey: 'facebook',
                      items: [
                        {
                          titleKey: 'profile',
                          width: 180,
                          height: 180,
                          descriptionKey: 'fbProfileSize',
                        },
                        {
                          titleKey: 'coverPhoto',
                          width: 820,
                          height: 312,
                          descriptionKey: 'fbCoverPhotoSize',
                        },
                      ],
                    },
                  ],
                },
              ],
            }}
            tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]}
            defaultTabId={TABS.ANNOTATE}
            defaultToolId={TOOLS.TEXT}
            config={{
              forceToPng: true,
            }}
          />

        )}
      </div>
    </div>
  );
};

export default ImageEditorModal;