import React, { useState, useEffect } from 'react';
import { message, Skeleton, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ProjectForm from './ProjectForm';
import { getProject, updateProject } from '../../services/projectServices';
import ButtonBar from "../../components/ButtonNavigation"

const ProjectEdit = () => {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);
  const project_id = queryParams.get("project_id"); const dispatch = useDispatch();


  // Fetch project using dispatch
  useEffect(() => {
    const fetchProject = () => {
      dispatch(getProject(project_id))
        .then((response) => {
          setProject(response);
        })
        .catch((error) => {
          message.error('Failed to fetch project');
          navigate('/projects');
        })
        .finally(() => {
          setLoading(false);
        });
    };

    fetchProject();
  }, [dispatch, project_id, navigate]);

  if (loading) {
    return (
      <div className=' container-xxl my-5  px-xl-5 px-lg-3'>
      <div className="Skeleton_cover-edit-create">

        <Row gutter={16} className="d-flex align-items-center justify-content-start " >
          {
            (() => {
              const cols = [];
              for (let i = 0; i < 9; i++) {
                cols.push(<Col xs={24} md={8} xl={8} key={i} className="sklpad d-flex align-items-center justify-content-start mb-4">
                  <Skeleton.Input active size="size" style={{ height: "18px" }} />
                  <Skeleton.Input block={true} active size="large" style={{ height: "42px", borderRadius: "6px" }} />
                </Col>
                );
              }
              return cols;
            })()
          }
          <Col xs={24} md={24} xl={24} className="sklpad d-flex align-items-center justify-content-start mb-4  mt-3">
            <Skeleton.Input active size="size" style={{ height: "18px" }} />
            <Skeleton.Input block={true} active size="large" style={{ height: "110px", borderRadius: "6px" }} />
          </Col>
          <Col xs={24} md={24} xl={24} className="sklpad d-flex flex-wrap-wrap align-items-center justify-content-start  mt-3">
            <Skeleton.Input active size="size" style={{ height: "18px" }} />
            <Skeleton.Input block={true} active size="large" style={{ height: "300px", borderRadius: "6px" }} />
          </Col>
          <Col xs={24} md={24} xl={24} className="sklpad d-flex align-items-center justify-content-center mb-4 mt-4">
            <Skeleton.Input active size="size" style={{ height: "48px", width: "190px", borderRadius: "10px" }} />
          </Col>
        </Row>
      </div>
      </div>
    );
  }

  // Handle form submission using dispatch
  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(updateProject(project_id, values))
      .then(() => {
        message.success('Project updated successfully');
        navigate('/projects');
      })
      .catch(() => {
        message.error('Failed to update project');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!project) return null;

  return (
    <div className='project-edit-section container-xxl mb-5  px-xl-5 px-lg-3'>
      <div className='button-navigations mt-5'>
        <ButtonBar />
      </div>
      <h1 className="edit-form-title my-4">Edit Project</h1>

      <ProjectForm
        initialValues={project}
        onSubmit={handleSubmit}
        loading={loading}
        buttonText="Update Project"
      />

    </div>
  );
};
export default ProjectEdit;
