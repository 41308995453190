import Http from "../Http";
import { LARAVEL_BACKEND_API_URL } from "../Helper";

// Project Services
export function getProjects(params = {}) {
  const queryParams = new URLSearchParams(params).toString();
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`${LARAVEL_BACKEND_API_URL}projects?${queryParams}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function createProject(data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`${LARAVEL_BACKEND_API_URL}projects`, data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getProject(projectId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`${LARAVEL_BACKEND_API_URL}projects/${projectId}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function updateProject(projectId, data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.patch(`${LARAVEL_BACKEND_API_URL}projects/${projectId}`, data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function deleteProject(projectId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(`${LARAVEL_BACKEND_API_URL}projects/${projectId}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

// Project Images Services
export function getProjectImages(projectId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function uploadProjectImage(projectId, data) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images`, data)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function getProjectImage(projectId, imageId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images/${imageId}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function updateProjectImage(projectId, imageId, data) {
  console.info({ projectId, imageId, data })
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images/${imageId}?_method=PATCH`, data, {
      })
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function deleteProjectImage(projectId, imageId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.delete(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images/${imageId}`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

export function downloadOriginalImage(projectId, imageId) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.get(`${LARAVEL_BACKEND_API_URL}projects/${projectId}/images/${imageId}/original`)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}