import React, { useState } from 'react';
import { message, Skeleton, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import ProjectForm from './ProjectForm';
import { createProject } from '../../services/projectServices';
import { useDispatch } from 'react-redux';
import ButtonBar from "../../components/ButtonNavigation"
const ProjectCreate = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    setLoading(true);
    dispatch(createProject(values))
      .then(() => {
        message.success('Project created successfully');
        navigate('/projects'); // Redirect after successful creation
      })
      .catch((error) => {
        message.error('Failed to create project');
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div className='project-edit-section container-xxl mb-5  px-xl-5 px-lg-3'>
      <div className='button-navigations mt-5'>
					<ButtonBar />
				</div>
      <h1  className="edit-form-title my-4">Create Project</h1>
      {loading ? (
               <div className="Skeleton_cover-edit-create">
               
                    <Row gutter={16} className="d-flex align-items-center justify-content-start " >
                    {
        (() => {
          const cols = [];
          for (let i = 0; i < 9; i++) {
            cols.push(<Col xs={24} md={8} xl={8} key={i} className="sklpad d-flex align-items-center justify-content-start mb-4">
                        <Skeleton.Input active size="size" style={{ height: "18px" }} />
                        <Skeleton.Input  block={true} active size="large" style={{ height: "42px", borderRadius: "6px" }} />
                      </Col>
                    );
                  }
                  return cols;
                })()
              }
                      <Col xs={24} md={24} xl={24} className="sklpad d-flex align-items-center justify-content-start mb-4  mt-3">
                        <Skeleton.Input active size="size" style={{ height: "18px" }} />
                        <Skeleton.Input  block={true} active size="large" style={{ height: "110px", borderRadius: "6px" }} />
                      </Col>
                      <Col xs={24} md={24} xl={24} className="sklpad d-flex flex-wrap-wrap align-items-center justify-content-start  mt-3">
                        <Skeleton.Input active size="size" style={{ height: "18px" }} />
                        <Skeleton.Input  block={true} active size="large" style={{ height: "300px", borderRadius: "6px" }} />
                      </Col>
                      <Col xs={24} md={24} xl={24} className="sklpad d-flex align-items-center justify-content-center mb-4 mt-4">
                        <Skeleton.Input active size="size" style={{ height: "48px", width:"190px", borderRadius: "10px" }} />
                      </Col>
                    </Row>
                </div>
               
            ) : (
            <>
            <ProjectForm onSubmit={handleSubmit} loading={loading}  buttonText="Create Project" />
            </>
      )}
    </div>
  );
};
export default ProjectCreate;
