import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Input,
  Card,
  Form,
  Button,
  DatePicker,
  Collapse,
  Tooltip,
  Spin,
  Modal,
  Cascader,
} from "antd";
import {
  PlusOutlined,
  MinusOutlined,
  SearchOutlined,
  CopyOutlined,
  FormOutlined,
  EyeOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import axios from "axios";
import { MEDIA_UPLOAD_URL } from "../../../Helper";
import TripleToggle from "../../../components/trippleToggle/trippleToggle";
import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from "suneditor/src/plugins";
import * as getSuggestionService from "../../../services/getSuggestion";

const optionsTemp = [
  {
    value: "°F",
    label: "°F",
  },
  {
    value: "°C",
    label: "°C",
  },
];

const SecondStep = (props) => {
  console.warn("SecondStep", props);
  var dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterStandards, setFilterStandards] = useState([]);
  const [modalIndustry, setModalIndustry] = useState(false);
  const [industryContent, setindustryContent] = useState("");
  const [sidebarState, setsidebarState] = useState();
  const [editorState1, setEditorState1] = useState("");
  const [editorState2, setEditorState2] = useState("");
  const [editorState3, setEditorState3] = useState("");
  const [editorState4, setEditorState4] = useState("");

  const getSuggestion = (label, category) => {
    setLoading(true);
    let suggestiondata = {
      label: label,
      category: category,
    };
    dispatch(getSuggestionService.getindStandard(suggestiondata))
      .then((res) => {
        setsidebarState(res);
        setLoading(false);
      })
      .catch((err) => {
          // Extract and display a meaningful error message
      let errorMessage;

      if (err.response) {
        // The request was made and the server responded with a status code outside of the 2xx range
        errorMessage = `Error ${err.response.status}: ${err.response.data.message || err.response.statusText}`;
      } else if (err.request) {
        // The request was made but no response was received
        errorMessage = "No response received from the server. Please check your network connection.";
      } else {
        // Something else happened while setting up the request
        errorMessage = `An unexpected error occurred: ${err.message}`;
      }

      // Display the error message in an alert or use a UI notification component
      alert(errorMessage);
      });
  };

  const onEditorStateChange1 = (editorData) => {
    setEditorState1(editorData);
    props.values.float_comm_party_comm = editorData;
    props.handleEditor(editorData, "float_comm_party_comm");
  };

  const onEditorStateChange2 = (editorData) => {
    setEditorState2(editorData);
    props.values.float_end_user_comm = editorData;
    props.handleEditor(editorData, "float_end_user_comm");
  };

  const onEditorStateChange3 = (editorData) => {
    setEditorState3(editorData);
    props.values.float_floor_con_comm = editorData;
    props.handleEditor(editorData, "float_floor_con_comm");
  };
  const onEditorStateChange4 = (editorData) => {
    setEditorState4(editorData);
    props.values.float_oth_inv_comm = editorData;
    props.handleEditor(editorData, "float_oth_inv_comm");
  };
  const SunEditorOpts = {
    showPathLabel: false,
    imageMultipleFile: true,
    imageWidth: "150px",
    imageHeight: "150px",
    height: "auto",
    minHeight: "200px",
    requestHeaders: {
      "X-Sample": "sample",
    },
    plugins: [
      align,
      font,
      fontColor,
      fontSize,
      formatBlock,
      hiliteColor,
      horizontalRule,
      lineHeight,
      list,
      paragraphStyle,
      table,
      template,
      textStyle,
      image,
      link,
    ],
    buttonList: [
      ["undo", "redo"],
      ["font", "fontSize", "formatBlock"],
      ["paragraphStyle"],
      ["bold", "underline", "italic", "strike", "subscript", "superscript"],
      ["fontColor", "hiliteColor"],
      ["removeFormat"],
      ["align", "horizontalRule","lineHeight"],
      ["outdent", "indent"],["list"],
      ["table", "link", "image"],
      ["fullScreen", "showBlocks", "preview"],
    ],
    formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
    font: [
      "Arial",
      "Calibri",
      "Comic Sans",
      "Courier",
      "Garamond",
      "Georgia",
      "Impact",
      "Lucida Console",
      "Palatino Linotype",
      "Segoe UI",
      "Tahoma",
      "Times New Roman",
      "Trebuchet MS",
      "Roboto",
    ],
  };
    const onImageUploadBefore = (files, info, uploadHandler) => {
    const formData = new FormData()
     const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt_token")}`,
      },
    };
    formData.append('file', files[0])
    axios.post(MEDIA_UPLOAD_URL, formData, config)
      .then((res)=>{
         const response = {
                // The response must have a "result" array.
                "result": [
                    {
                        "url": res.data.source_url,
                        "name": files[0].name,
                        "size": files[0].size
                    },
            ]}
        uploadHandler(response)
      })
  }
  useEffect(() => {
    console.info("Useffect Called");
    props.values.float_comm_party_comm
      ? setEditorState1(props.values.float_comm_party_comm)
      : setEditorState1("");
    props.values.float_end_user_comm
      ? setEditorState2(props.values.float_end_user_comm)
      : setEditorState2("");
    props.values.float_floor_con_comm
      ? setEditorState3(props.values.float_floor_con_comm)
      : setEditorState3("");
    props.values.float_oth_inv_comm
      ? setEditorState4(props.values.float_oth_inv_comm)
      : setEditorState4("");
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    useEffect(() => {
    if (sidebarState) {
      const filteredData = sidebarState.filter((entry) =>
        entry?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilterStandards(filteredData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const DateFormat = "MM-DD-YYYY";
  const { TextArea } = Input;

  const handleClick = (item1) => {
    setModalIndustry(true);
    sidebarState.filter((item) =>
      item.id === item1 ? setindustryContent(item) : ""
    );
  };
  const sendTextToEditor = (text, label) => {
//     if (label === "cause") {
//        setEditorState4(editorState4 + text);
//        onEditorStateChange4(editorState4 + text);
//     } else if (label === "applicable-field-tests") {
//      setEditorState1(editorState1 + text);
//      onEditorStateChange1(editorState1 + text);
//   } else if (label === "conclusion") {
//    setEditorState2(editorState2 + text);
//    onEditorStateChange2(editorState2 + text);
//  } else if (label === "industry_standards") {
//    setEditorState3(editorState3 + text);
//    onEditorStateChange3(editorState3 + text);
//  }
   };
  return (
    <>
      <Form name="report" autoComplete="off" layout="vertical" form={form}>
        <Card className="reportCard">
          <div className="reportCardBody">
            <div className="formSection">
              <div className="inspectionSection">
                <div className="CollapseSection">
                  <Collapse
                    defaultActiveKey={
                      props.values.stepTwoTogg1
                        ? props.values.stepTwoTogg1
                        : ["1", "2", "3", "4"]
                    }
                    onChange={props.handleChange("stepTwoTogg1")}
                    className="reportCollapse"
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <MinusOutlined className="custom-icon" />
                      ) : (
                        <PlusOutlined className="custom-icon" />
                      )
                    }
                    ghost
                  >
                    <Panel header="Claim History" key="1">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Sanding"
                              name="date_of_sanding"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Sanding"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_of_sanding",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_of_sanding}
                                  onFocus={() =>
                                    props.setCalOpen6(!props.calOpen6)
                                  }
                                  onKeyUp={() => props.setCalOpen6(false)}
                                  onBlur={() => props.setCalOpen6(false)}
                                />
                               
                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen6(!props.calOpen6)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen6}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_of_sanding",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date of Completion"
                              name="date_completion"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date of Completion"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_completion",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_completion}
                                  onFocus={() =>
                                    props.setCalOpen9(!props.calOpen9)
                                  }
                                  onKeyUp={() => props.setCalOpen9(false)}
                                  onBlur={() => props.setCalOpen9(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen9(!props.calOpen9)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen9}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_completion",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Occupied"
                              name="date_occupied"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Occupied"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_occupied",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_occupied}
                                  onFocus={() =>
                                    props.setCalOpen10(!props.calOpen10)
                                  }
                                  onKeyUp={() => props.setCalOpen10(false)}
                                  onBlur={() => props.setCalOpen10(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen10(!props.calOpen10)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen10}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_occupied",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Complaint First Noticed"
                              name="date_completion_first"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Complaint First Noticed"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_completion_first",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_completion_first}
                                  onFocus={() =>
                                    props.setCalOpen11(!props.calOpen11)
                                  }
                                  onKeyUp={() => props.setCalOpen11(false)}
                                  onBlur={() => props.setCalOpen11(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen11(!props.calOpen11)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen11}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_completion_first",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Date Reported"
                              name="date_reported"
                            >
                              <Input.Group compact className="mt-0">
                                <Input
                                  placeholder="Date Reported"
                                  className="formControl"
                                  onChange={(e) =>
                                    props.handleDateformate(
                                      e,
                                      "date_reported",
                                      "text"
                                    )
                                  }
                                  value={props.values.date_reported}
                                  onFocus={() =>
                                    props.setCalOpen12(!props.calOpen12)
                                  }
                                  onKeyUp={() => props.setCalOpen12(false)}
                                  onBlur={() => props.setCalOpen12(false)}
                                />

                                <Button
                                  className="btnAddOn"
                                  icon={<CalendarOutlined />}
                                  onClick={() =>
                                    props.setCalOpen12(!props.calOpen12)
                                  }
                                />
                              </Input.Group>
                              <DatePicker
                                className="formControl dateHide"
                                format={DateFormat}
                                open={props.calOpen12}
                                style={{ visibility: "hidden", width: 0 }}
                                onChange={(e) =>
                                  props.handleDateformate(
                                    e,
                                    "date_reported",
                                    "date"
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item label="Reported to" name="reported_to">
                              <Input
                                placeholder="Reported to"
                                className="formControl"
                                onChange={props.handleChange("reported_to")}
                                defaultValue={props.values.reported_to}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Cleaning Products/Methods Used"
                              name="clean_product"
                            >
                              <Input
                                placeholder="Cleaning Products/Methods Used"
                                className="formControl"
                                onChange={props.handleChange("clean_product")}
                                defaultValue={props.values.clean_product}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="How Often Cleaned?"
                              name="how_often"
                            >
                              <Input
                                placeholder="How Often Cleaned?"
                                className="formControl"
                                onChange={props.handleChange("how_often")}
                                defaultValue={props.values.how_often}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Unusual Events"
                              name="unusual_events"
                            >
                              <Input
                                placeholder="Unusual Events"
                                className="formControl"
                                onChange={props.handleChange("unusual_events")}
                                defaultValue={props.values.unusual_events}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel
                      header="Jobsite Conditions During Sanding and Finishing Process"
                      key="2"
                    >
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Moisture Content of Floor"
                              name="mois_con_of_floor"
                            >
                              <Input
                                placeholder="Moisture Content of Floor"
                                className="formControl"
                                onChange={props.handleChange(
                                  "mois_con_of_floor"
                                )}
                                defaultValue={props.values.mois_con_of_floor}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Method/Meter Used"
                              name="mtr_mthod_used"
                            >
                              <Input
                                placeholder="Method/Meter Used"
                                className="formControl"
                                onChange={props.handleChange("mtr_mthod_used")}
                                defaultValue={props.values.mtr_mthod_used}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup custom-form-group">
                            <Form.Item
                              label="Temperature (Interior)"
                              name="temp_during_ins"
                            >
                              <Input
                                className="formGroup customPadding"
                                addonAfter={
                                  <Cascader
                                    placeholder="F/C"
                                    onChange={props.handleChange("temp_f_c")}
                                    options={optionsTemp}
                                    defaultValue={props.values.temp_f_c}
                                    className="custom-cascadar"
                                  />
                                }
                                placeholder="Temperature (Interior)"
                                onChange={props.handleChange("temp_during_ins")}
                                defaultValue={props.values.temp_during_ins}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="RH (Interior)"
                              name="rh_during_ins"
                            >
                              <Input
                                placeholder="RH (Interior)"
                                className="formControl"
                                onChange={props.handleChange("rh_during_ins")}
                                defaultValue={props.values.rh_during_ins}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="HVAC Operational"
                              name="hvac_oper_during_storage"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.hvac_oper_during_storage}
                                    onToggle={props.handleChange('hvac_oper_during_storage')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </Panel>
                    <Panel
                      header="Sanding Process (Equipment & Abrasives)"
                      key="3"
                    >
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Belt/Drum Sander"
                              name="belt_drum_sander"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.belt_drum_sander}
                                    onToggle={props.handleChange('belt_drum_sander')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.belt_drum_sanderField === true ||
                        props.values.belt_drum_sander === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="belt_drum_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "belt_drum_grit_seq"
                                    )}
                                    defaultValue={
                                      props.values.belt_drum_grit_seq
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="belt_drum_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "belt_drum_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.belt_drum_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                               label="Edger" name="edger"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.edger}
                                    onToggle={props.handleChange('edger')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.edgerField === true ||
                        props.values.edger === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="edger_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "edger_grit_seq"
                                    )}
                                    defaultValue={props.values.edger_grit_seq}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="edger_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "edger_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.edger_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                              label="Orbital Sander"
                              name="orbi_sander"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.orbi_sander}
                                    onToggle={props.handleChange('orbi_sander')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.orbi_sanderField === true ||
                        props.values.orbi_sander === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="orbi_sander_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "orbi_sander_grit_seq"
                                    )}
                                    defaultValue={
                                      props.values.orbi_sander_grit_seq
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="orbi_sander_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "orbi_sander_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.orbi_sander_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                               label="Buffer" name="buffer"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.buffer}
                                    onToggle={props.handleChange('buffer')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.bufferField === true ||
                        props.values.buffer === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence & Pads/Papers Used"
                                  name="buffer_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence & Pads/Papers Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "buffer_grit_seq"
                                    )}
                                    defaultValue={props.values.buffer_grit_seq}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="buffer_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "buffer_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.buffer_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                               label="Hard Plate/Multi-Head Attachment"
                               name="hard_plate_multi_head"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.hard_plate_multi_head}
                                    onToggle={props.handleChange('hard_plate_multi_head')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.hard_plate_multi_headField === true ||
                        props.values.hard_plate_multi_head === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="hard_plate_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "hard_plate_grit_seq"
                                    )}
                                    defaultValue={
                                      props.values.hard_plate_grit_seq
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="abrasive_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "abrasive_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.abrasive_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                label="Planetary Sander"
                                name="planetary_sander"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.planetary_sander}
                                    onToggle={props.handleChange('planetary_sander')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.planetary_sanderField === true ||
                        props.values.planetary_sander === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="planetary_sander_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "planetary_sander_grit_seq"
                                    )}
                                    defaultValue={
                                      props.values.planetary_sander_grit_seq
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="plant_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "plant_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.plant_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                 label="Other Sanders" name="oth_sanders"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.oth_sanders}
                                    onToggle={props.handleChange('oth_sanders')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.oth_sandersField === true ||
                        props.values.oth_sanders === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Grit Sequence"
                                  name="oth_sanders_grit_seq"
                                >
                                  <Input
                                    placeholder="Grit Sequence"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "oth_sanders_grit_seq"
                                    )}
                                    defaultValue={
                                      props.values.oth_sanders_grit_seq
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Abrasive Used"
                                  name="oth_sanders_abrasive_used"
                                >
                                  <Input
                                    placeholder="Abrasive Used"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "oth_sanders_abrasive_used"
                                    )}
                                    defaultValue={
                                      props.values.oth_sanders_abrasive_used
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                          <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                           
                            <Form.Item
                                label="Dust Containment"
                                name="dust_contai"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.dust_contai}
                                    onToggle={props.handleChange('dust_contai')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                              label="Method of Containing Dust"
                              name="method_dust_contai"
                            >
                              <Input
                                placeholder="Method of Containing Dust"
                                className="formControl"
                                onChange={props.handleChange(
                                  "method_dust_contai"
                                )}
                                defaultValue={props.values.method_dust_contai}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                       </Row>
                    </Panel>
                    <Panel header="Finish/Colorant" key="4">
                      <Row gutter={16}>
                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                label="Stain/Colorant Type(s)"
                                name="Stain_color_type"
                                  >
                                    {console.warn("tripleToggle")}
                                    <TripleToggle
                                    value={props.values.Stain_color_type}
                                    onToggle={props.handleChange('Stain_color_type')}
                                  />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.Stain_color_typeField === true ||
                        props.values.Stain_color_type === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Product(s)"
                                  name="finish_products"
                                >
                                  <Input
                                    placeholder="Product(s)"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_products"
                                    )}
                                    defaultValue={props.values.finish_products}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Color" name="finish_color">
                                  <Input
                                    placeholder="Color"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_color"
                                    )}
                                    defaultValue={props.values.finish_color}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item label="Quantity Used" name="q_used">
                                  <Input
                                    placeholder="Quantity Used"
                                    className="formControl"
                                    onChange={props.handleChange("q_used")}
                                    defaultValue={props.values.q_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Coverage Rate"
                                  name="covera_rat"
                                >
                                  <Input
                                    placeholder="Coverage Rate"
                                    className="formControl"
                                    onChange={props.handleChange("covera_rat")}
                                    defaultValue={props.values.covera_rat}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Total Square Footage"
                                  name="tot_sqare_footage"
                                >
                                  <Input
                                    placeholder="Total Square Footage"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "tot_sqare_footage"
                                    )}
                                    defaultValue={
                                      props.values.tot_sqare_footage
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Application Method/Tool"
                                  name="app_m_tool"
                                >
                                  <Input
                                    placeholder="Application Method/Tool"
                                    className="formControl"
                                    onChange={props.handleChange("app_m_tool")}
                                    defaultValue={props.values.app_m_tool}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Coats"
                                  name="nof_coats"
                                >
                                  <Input
                                    placeholder="Number of Coats"
                                    className="formControl"
                                    onChange={props.handleChange("nof_coats")}
                                    defaultValue={props.values.nof_coats}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of Application"
                                  name="dateof_appli"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of Application"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "dateof_appli",
                                          "text"
                                        )
                                      }
                                      value={props.values.dateof_appli}
                                      onFocus={() =>
                                        props.setCalOpen22(!props.calOpen22)
                                      }
                                      onKeyUp={() => props.setCalOpen22(false)}
                                      onBlur={() => props.setCalOpen22(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen22(!props.calOpen22)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen22}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "dateof_appli",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Manufacturer Requirements"
                                    name="manufac_req"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.manufac_req}
                                        onToggle={props.handleChange('manufac_req')}
                                      />
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.manufac_reqField === true ||
                            props.values.manufac_req === 'Yes' ? (
                              <>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Coverage Rate"
                                      name="manu_co_rate"
                                    >
                                      <Input
                                        placeholder="Coverage Rate"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "manu_co_rate"
                                        )}
                                        defaultValue={props.values.manu_co_rate}
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Application Method"
                                      name="manu_applica_method"
                                    >
                                      <Input
                                        placeholder="Application Method"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "manu_applica_method"
                                        )}
                                        defaultValue={
                                          props.values.manu_applica_method
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Dry Time Requirements"
                                      name="manu_day_time_req"
                                    >
                                      <Input
                                        placeholder="Dry Time Requirements"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "manu_day_time_req"
                                        )}
                                        defaultValue={
                                          props.values.manu_day_time_req
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Process/System"
                                  name="process_system"
                                >
                                  <TextArea
                                    placeholder="Process/System"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "process_system"
                                    )}
                                    defaultValue={props.values.process_system}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            
                            <Form.Item
                                   label="Sealer Type(s)"
                                   name="selaer_type"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.selaer_type}
                                        onToggle={props.handleChange('selaer_type')}
                                      />
                            </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.selaer_typeField === true ||
                        props.values.selaer_type === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Product by Sealer"
                                  name="sealer_Product"
                                >
                                  <Input
                                    placeholder="Product by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "sealer_Product"
                                    )}
                                    defaultValue={props.values.sealer_Product}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Production Date by Sealer"
                                  name="production_date"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Production Date by Sealer"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "production_date",
                                          "text"
                                        )
                                      }
                                      value={props.values.production_date}
                                      onFocus={() =>
                                        props.setCalOpen8(!props.calOpen8)
                                      }
                                      onKeyUp={() => props.setCalOpen8(false)}
                                      onBlur={() => props.setCalOpen8(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen8(!props.calOpen8)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen8}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "production_date",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Lot Number by Sealer"
                                  name="lot_number_by_sealer"
                                >
                                  <Input
                                    placeholder="Lot Number by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "lot_number_by_sealer"
                                    )}
                                    defaultValue={
                                      props.values.lot_number_by_sealer
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Total Square Footage by Sealer"
                                  name="sealer_totalsquare"
                                >
                                  <Input
                                    placeholder="Total Square Footage by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "sealer_totalsquare"
                                    )}
                                    defaultValue={
                                      props.values.sealer_totalsquare
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Quantity Used by Sealer"
                                  name="product_q_used"
                                >
                                  <Input
                                    placeholder="Quantity Used by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "product_q_used"
                                    )}
                                    defaultValue={props.values.product_q_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Coverage Rate by Sealer"
                                  name="prod_covera_rat"
                                >
                                  <Input
                                    placeholder="Coverage Rate by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "prod_covera_rat"
                                    )}
                                    defaultValue={props.values.prod_covera_rat}
                                  />
                                </Form.Item>
                              </div>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Application Method/Tool by Sealer"
                                  name="produ_app_m_tool"
                                >
                                  <Input
                                    placeholder="Application Method/Tool by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "produ_app_m_tool"
                                    )}
                                    defaultValue={props.values.produ_app_m_tool}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Coats by Sealer"
                                  name="prod_nof_coats"
                                >
                                  <Input
                                    placeholder="Number of Coats by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "prod_nof_coats"
                                    )}
                                    defaultValue={props.values.prod_nof_coats}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of Application by Sealer"
                                  name="product_dateof_appli"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of Application by Sealer"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "product_dateof_appli",
                                          "text"
                                        )
                                      }
                                      value={props.values.product_dateof_appli}
                                      onFocus={() =>
                                        props.setCalOpen21(!props.calOpen21)
                                      }
                                      onKeyUp={() => props.setCalOpen21(false)}
                                      onBlur={() => props.setCalOpen21(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen21(!props.calOpen21)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen21}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "product_dateof_appli",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                
                                <Form.Item
                                    label="Manufacturer Requirements by Sealer"
                                    name="prod_manufac_req"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.prod_manufac_req}
                                        onToggle={props.handleChange('prod_manufac_req')}
                                      />
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.prod_manufac_reqField === true ||
                            props.values.prod_manufac_req === 'Yes' ? (
                              <>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Coverage Rate by Sealer"
                                      name="product_manu_co_rate"
                                    >
                                      <Input
                                        placeholder="Coverage Rate by Sealer"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "product_manu_co_rate"
                                        )}
                                        defaultValue={
                                          props.values.product_manu_co_rate
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Application Method by Sealer"
                                      name="prod_manu_applica_method"
                                    >
                                      <Input
                                        placeholder="Application Method by Sealer"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "prod_manu_applica_method"
                                        )}
                                        defaultValue={
                                          props.values.prod_manu_applica_method
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Dry Time Requirements by Sealer"
                                      name="prod_manu_day_time_req"
                                    >
                                      <Input
                                        placeholder="Dry Time Requirements by Sealer"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "prod_manu_day_time_req"
                                        )}
                                        defaultValue={
                                          props.values.prod_manu_day_time_req
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Process/System by Sealer"
                                  name="product_process_system"
                                >
                                  <TextArea
                                    placeholder="Process/System by Sealer"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "product_process_system"
                                    )}
                                    defaultValue={
                                      props.values.product_process_system
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}

                        <Col xs={24} md={6} xl={6}>
                          <div className="formGroup">
                            <Form.Item
                                     label="Finish Type(s)"
                                     name="finish_type"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.finish_type}
                                        onToggle={props.handleChange('finish_type')}
                                      />
                                </Form.Item>
                          </div>
                        </Col>
                        {props.isOpen.finish_typeField === true ||
                        props.values.finish_type === 'Yes' ? (
                          <>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Product by Finish Type"
                                  name="finish_Product"
                                >
                                  <Input
                                    placeholder="Product by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_Product"
                                    )}
                                    defaultValue={props.values.finish_Product}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Sheen by Finish Type"
                                  name="Sheen_finish"
                                >
                                  <Input
                                    placeholder="Product by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "Sheen_finish"
                                    )}
                                    defaultValue={props.values.Sheen_finish}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Production Date by Finish Type"
                                  name="finish_production_date"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Production Date by Finish Type"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "finish_production_date",
                                          "text"
                                        )
                                      }
                                      value={
                                        props.values.finish_production_date
                                      }
                                      onFocus={() =>
                                        props.setCalOpen7(!props.calOpen7)
                                      }
                                      onKeyUp={() => props.setCalOpen7(false)}
                                      onBlur={() => props.setCalOpen7(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen7(!props.calOpen7)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen7}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "finish_production_date",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Lot Number by Finish Type"
                                  name="lot_number_by_fini"
                                >
                                  <Input
                                    placeholder="Lot Number by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "lot_number_by_fini"
                                    )}
                                    defaultValue={
                                      props.values.lot_number_by_fini
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Total Square Footage by Finish Type"
                                  name="finish_totalsquare"
                                >
                                  <Input
                                    placeholder="Total Square Footage by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_totalsquare"
                                    )}
                                    defaultValue={
                                      props.values.finish_totalsquare
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Quantity Used by Finish Type"
                                  name="finsih_q_used"
                                >
                                  <Input
                                    placeholder="Quantity Used by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finsih_q_used"
                                    )}
                                    defaultValue={props.values.finsih_q_used}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Coverage Rate by Finish Type"
                                  name="finish_prod_covera_rat"
                                >
                                  <Input
                                    placeholder="Coverage Rate by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_prod_covera_rat"
                                    )}
                                    defaultValue={
                                      props.values.finish_prod_covera_rat
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>

                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Application Method/Tool by Finish Type"
                                  name="finish_app_m_tool"
                                >
                                  <Input
                                    placeholder="Application Method/Tool by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_app_m_tool"
                                    )}
                                    defaultValue={
                                      props.values.finish_app_m_tool
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Number of Coats by Finish Type"
                                  name="finish_nof_coats"
                                >
                                  <Input
                                    placeholder="Number of Coats by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "finish_nof_coats"
                                    )}
                                    defaultValue={props.values.finish_nof_coats}
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of 1st Coat Finish"
                                  name="date_st_coat_finish"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of 1st Coat Finish"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "date_st_coat_finish",
                                          "text"
                                        )
                                      }
                                      value={props.values.date_st_coat_finish}
                                      onFocus={() =>
                                        props.setCalOpen17(!props.calOpen17)
                                      }
                                      onKeyUp={() => props.setCalOpen17(false)}
                                      onBlur={() => props.setCalOpen17(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen17(!props.calOpen17)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen17}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "date_st_coat_finish",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of 2nd Coat"
                                  name="finish_nd_coat"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of 2nd Coat"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "finish_nd_coat",
                                          "text"
                                        )
                                      }
                                      value={props.values.finish_nd_coat}
                                      onFocus={() =>
                                        props.setCalOpen18(!props.calOpen18)
                                      }
                                      onKeyUp={() => props.setCalOpen18(false)}
                                      onBlur={() => props.setCalOpen18(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen18(!props.calOpen18)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen18}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "finish_nd_coat",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of 3rd Coat"
                                  name="finish_rd_coat"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of 3rd Coat"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "finish_rd_coat",
                                          "text"
                                        )
                                      }
                                      value={props.values.finish_rd_coat}
                                      onFocus={() =>
                                        props.setCalOpen19(!props.calOpen19)
                                      }
                                      onKeyUp={() => props.setCalOpen19(false)}
                                      onBlur={() => props.setCalOpen19(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen19(!props.calOpen18)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen19}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "finish_rd_coat",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Date of Other Coats"
                                  name="other_coat"
                                >
                                  <Input.Group compact className="mt-0">
                                    <Input
                                      placeholder="Date of Other Coats"
                                      className="formControl"
                                      onChange={(e) =>
                                        props.handleDateformate(
                                          e,
                                          "other_coat",
                                          "text"
                                        )
                                      }
                                      value={props.values.other_coat}
                                      onFocus={() =>
                                        props.setCalOpen20(!props.calOpen20)
                                      }
                                      onKeyUp={() => props.setCalOpen20(false)}
                                      onBlur={() => props.setCalOpen20(false)}
                                    />

                                    <Button
                                      className="btnAddOn"
                                      icon={<CalendarOutlined />}
                                      onClick={() =>
                                        props.setCalOpen20(!props.calOpen20)
                                      }
                                    />
                                  </Input.Group>
                                  <DatePicker
                                    className="formControl dateHide"
                                    format={DateFormat}
                                    open={props.calOpen20}
                                    style={{ visibility: "hidden", width: 0 }}
                                    onChange={(e) =>
                                      props.handleDateformate(
                                        e,
                                        "other_coat",
                                        "date"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                            <Col xs={24} md={6} xl={6}>
                              <div className="formGroup">
                              
                                <Form.Item
                                    label="Manufacturer Requirements by Finish Type"
                                    name="finish_manufac_req"
                                      >
                                        {console.warn("tripleToggle")}
                                        <TripleToggle
                                        value={props.values.finish_manufac_req}
                                        onToggle={props.handleChange('finish_manufac_req')}
                                      />
                                </Form.Item>
                              </div>
                            </Col>
                            {props.isOpen.finish_manufac_reqField === true ||
                            props.values.finish_manufac_req === 'Yes' ? (
                              <>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Coverage Rate by Finish Type"
                                      name="finish_manu_co_rate"
                                    >
                                      <Input
                                        placeholder="Coverage Rate by Finish Type"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "finish_manu_co_rate"
                                        )}
                                        defaultValue={
                                          props.values.finish_manu_co_rate
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Application Method by Finish Type"
                                      name="finish_manu_applica_method"
                                    >
                                      <Input
                                        placeholder="Application Method by Finish Type"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "finish_manu_applica_method"
                                        )}
                                        defaultValue={
                                          props.values
                                            .finish_manu_applica_method
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                                <Col xs={24} md={6} xl={6}>
                                  <div className="formGroup">
                                    <Form.Item
                                      label="Dry Time Requirements by Finish Type"
                                      name="fini_day_time_req"
                                    >
                                      <Input
                                        placeholder="Dry Time Requirements by Finish Type"
                                        className="formControl"
                                        onChange={props.handleChange(
                                          "fini_day_time_req"
                                        )}
                                        defaultValue={
                                          props.values.fini_day_time_req
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                </Col>
                              </>
                            ) : (
                              ""
                            )}
                            <Col xs={24} md={24} xl={24}>
                              <div className="formGroup">
                                <Form.Item
                                  label="Process/System by Finish Type"
                                  name="process_system_finishtype"
                                >
                                  <TextArea
                                    placeholder="Process/System by Finish Type"
                                    className="formControl"
                                    onChange={props.handleChange(
                                      "process_system_finishtype"
                                    )}
                                    defaultValue={
                                      props.values.process_system_finishtype
                                    }
                                  />
                                </Form.Item>
                              </div>
                            </Col>
                          </>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Panel>
                  </Collapse>
                </div>
                <div className="CollapseSection">
                  <div className="customObservationPanel">
                    <Row gutter={16}>
                      <Col xs={24} md={16} xl={16}>
                        <Collapse
                          defaultActiveKey={
                            props.values.stepTwoTogg2
                              ? props.values.stepTwoTogg2
                              : ["1", "2", "3", "4"]
                          }
                          onChange={props.handleChange("stepTwoTogg2")}
                          className="reportCollapse"
                          expandIcon={({ isActive }) =>
                            isActive ? (
                              <MinusOutlined className="custom-icon" />
                            ) : (
                              <PlusOutlined className="custom-icon" />
                            )
                          }
                          ghost
                        >
                          <Panel header="Commissioning Party Comments" key="1">
                          <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="m-2"><i><b>
                                  Note: Please list the source from which standards were applied . Then copy/paste specific areas of content that directly apply to the Statement of Concern.</b> </i> </div>
                              </Col>
                            </Row>
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        // getSuggestion(
                                        //   "applicable-field-tests",
                                        //   "Hardwood"
                                        // );
                                        getSuggestion(
                                          "float_comm_party_comm",
                                          "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState1}
                                      onChange={onEditorStateChange1}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="End-User Comments" key="2">
                          {/* <Row gutter={16}>
                              <Col xs={24} md={24}>
                                <div className="formGroup">
                                  <p>
                                    Please list all of the test performed along with the results and tool used for the tests. Add reference to supporting photos.
                                    </p>
                                </div>
                              </Col>
                            </Row> */}
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        // getSuggestion(
                                        //   "conclusion",
                                        //   "Hardwood"
                                        // );
                                        getSuggestion(
                                        "float_end_user_comm",
                                          "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState2}
                                      onChange={onEditorStateChange2}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Flooring Contractor Comments" key="3">
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        // getSuggestion(
                                        //   "industry_standards",
                                        //   "Hardwood"
                                        // );
                                        getSuggestion(
                                        "float_floor_con_comm",
                                        "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState3}
                                      onChange={onEditorStateChange3}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                          <Panel header="Other Involved Party Comments" key="4">
                            <Row gutter={16}>
                              <Col xs={24} md={24} xl={24}>
                                <div className="formGroup">
                                  <div className="text-editor-wrapper">
                                    <SunEditor
                                      onFocus={(event) => {
                                        // getSuggestion(
                                        //   "cause",
                                        //   "Hardwood"
                                        // );
                                        getSuggestion(
                                        "float_oth_inv_comm",
                                        "nwfa-sand-finish"
                                        );
                                      }}
                                      setDefaultStyle="font-family: 'Poppins', sans-serif; font-size: 14px;"
                                      setOptions={SunEditorOpts}
                                      onImageUploadBefore={onImageUploadBefore}
                                      setContents={editorState4}
                                      onChange={onEditorStateChange4}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Panel>
                        </Collapse>
                      </Col>
                      <Col xs={24} md={8} xl={8} className="sticySidebar">
                        <Card className="custom-sidecard">
                          <h5 className="formGroupTitle1">
                            Applied Industry Standards
                          </h5>
                          <div className="searchWrapper">
                            <Input
                              prefix={<SearchOutlined />}
                              placeholder="Search for names.."
                              className="formControl"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                            <p>
                            Your citations will start appearing below when you click into any section of the report.
                            </p>
                            {loading ? (
                              <div className="spinDiv">
                                <Spin size="large" />
                              </div>
                            ) : (
                              <div className="innerCardWrap">
                                {filterStandards && search
                                  ? filterStandards?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip>
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })
                                  : sidebarState?.map((item, index) => {
                                      return (
                                        <Card
                                          className="industryCard mb-2 sidebarCard"
                                          key={index}
                                        >
                                          <div className="industryCardBody">
                                            <div className="cardTopSection">
                                              <div className="headerSec">
                                                <h3
                                                  className="industryTitle"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                  }}
                                                ></h3>
                                                <p
                                                  className="industryDesc"
                                                  dangerouslySetInnerHTML={{
                                                    __html: item.excerpt,
                                                  }}
                                                ></p>
                                              </div>
                                            </div>
                                            <div className="contentSec">
                                              <div className="btnGroup">
                                                <Tooltip title="Copy/Paste">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn viewBtn"
                                                    onClick={() =>
                                                      sendTextToEditor(
                                                        item.description,
                                                        item.label
                                                      )
                                                    }
                                                  >
                                                    <CopyOutlined />
                                                  </Button>
                                                </Tooltip>
                                                {/* <Tooltip title="Edit">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                  >
                                                    <FormOutlined />
                                                  </Button>
                                                </Tooltip> */}
                                                <Tooltip title="View More">
                                                  <Button
                                                    type="text"
                                                    id={item.id}
                                                    className="indstryBtn"
                                                    onClick={() =>
                                                      handleClick(item.id)
                                                    }
                                                  >
                                                    <EyeOutlined />
                                                  </Button>
                                                </Tooltip>
                                              </div>
                                            </div>
                                          </div>
                                        </Card>
                                      );
                                    })}
                              </div>
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Form>
      <Modal
        title={industryContent.title}
        centered
        open={modalIndustry}
        closable={false}
        className="inspectionCategoryModal industryModal"
        width={1000}
        footer={[
          <Button
            key="back"
            onClick={() => setModalIndustry(false)}
            className="submitBtn"
          >
            Ok
          </Button>,
        ]}
      >
        <div className="modalContent">
          <div className="formGroup">
            <p
              className="mcDescription"
              dangerouslySetInnerHTML={{ __html: industryContent.description }}
            ></p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SecondStep;
